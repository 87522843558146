import { connect } from "react-redux";
import TextHeaderWithFilter from "../../components/TabHeaders/TextHeaderWithFilter";
import { CONFIG_CLIENTS } from "../../routes/utils";
import { tabActions, tabSelectors } from "../../store/tab";

export default connect(
  (state) => ({
    tabSelected: tabSelectors.tabSelected(state),
    filters: tabSelectors.filtersTabSelected(state),
  }),
  (dispatch) => ({
    updateFilter: (filterKey, value, tabSelected, columnName) => {
      dispatch(tabActions.updateFilter(filterKey, value, tabSelected, columnName));
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
    updateFilter: (filterKey, value, location, columnName = null) => {
      dispatchToProps.updateFilter(filterKey, value, location.pathname === CONFIG_CLIENTS ? 'configClient' : stateToProps.tabSelected, columnName);
    },
  })
)(TextHeaderWithFilter);
