/* eslint-disable import/no-anonymous-default-export */
import types from "./types";

const updateToast = (msg, severity) => ({
  type: types.UPDATE_TOAST,
  payload: {
    msg,
    severity,
  },
});

const resetToast = () => ({
  type: types.RESET_TOAST,
});

export default { updateToast, resetToast };
