import { Chip, useTheme } from "@mui/material";

const PersonFormatter = ({ persons = [], users }) => {
  const theme = useTheme();
  return (
    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
      {persons?.map((person) => {
        return (
          <Chip
            key={person.username}
            label={person.username}
            style={{
              fontWeight: 400,
              color: theme.palette.internalPrimary.main,
            }}
          />
        );
      })}
    </div>
  );
};
export default PersonFormatter;
