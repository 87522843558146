import { useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TAB } from "../../routes/utils";
import {
  StyledOngletName,
  StyledGrid,
  StyledHeader,
  StyledContainer,
  StyledColumnName,
  StyledCell,
  StyledDataVide,
  StyledDataNonVide,
  StyledCellHeader,
  StyledSubGrid,
} from "./styles";

const getPourcentage = (element, global) => {
  const globalTot = global.vide + global.nonVide;
  return globalTot ? element / globalTot : 0;
};

const DashboardTable = ({
  redirectWithFilter,
  dashboardBytab,
  tabsColumnsMapNamed,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleDedirectWithFilter = (
    tabsColumnsMapNamed,
    isNotEmptyOrDone,
    column,
    tab
  ) => {
    redirectWithFilter(
      tab.id,
      tabsColumnsMapNamed[column].type,
      tabsColumnsMapNamed[column].name,
      column,
      isNotEmptyOrDone
    );
    navigate(TAB, { state: tab });
  };

  return (
    <StyledContainer>
      <StyledGrid theme={theme}>
        <StyledCellHeader>
          <StyledOngletName theme={theme}>
            {dashboardBytab.tab.name}
          </StyledOngletName>
        </StyledCellHeader>
        <StyledCellHeader>
          <StyledHeader theme={theme}>Fait</StyledHeader>
        </StyledCellHeader>
        <StyledCellHeader>
          <StyledHeader theme={theme}>Non-Fait</StyledHeader>
        </StyledCellHeader>
        {Object.keys(dashboardBytab.resultats).map((column, i) => {
          const videData = getPourcentage(
            dashboardBytab.resultats[column]?.vide,
            dashboardBytab.resultats[column]
          );
          const notVideData = getPourcentage(
            dashboardBytab.resultats[column]?.nonVide,
            dashboardBytab.resultats[column]
          );
          return (
            <StyledSubGrid
              key={i}
              onClick={() =>
                handleDedirectWithFilter(
                  tabsColumnsMapNamed,
                  true,
                  column,
                  dashboardBytab.tab
                )
              }
            >
              <StyledCell>
                <StyledColumnName theme={theme}>
                  {tabsColumnsMapNamed[column].name}
                </StyledColumnName>
              </StyledCell>
              <StyledCell>
                <StyledDataNonVide theme={theme} data={notVideData}>
                  {dashboardBytab.resultats[column]?.nonVide}
                </StyledDataNonVide>
              </StyledCell>
              <StyledCell>
                <StyledDataVide theme={theme} data={videData}>
                  {dashboardBytab.resultats[column]?.vide}
                </StyledDataVide>
              </StyledCell>
            </StyledSubGrid>
          );
        })}
      </StyledGrid>
    </StyledContainer>
  );
};

export default DashboardTable;
