import { combineReducers } from "redux";
import tabs from "./tab";
import toast from "./toast";
import client from "./client";
import user from "./user";
import dashboard from "./dashboard";

export default combineReducers({
  tabs,
  toast,
  client,
  user,
  dashboard,
});
