import { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

const Toast = ({ msg, severity, resetToast }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    resetToast();
  };

  useEffect(() => {
    if (msg) {
      setOpen(true);
    }
  }, [msg]);

  return msg ? (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {msg}
      </Alert>
    </Snackbar>
  ) : (
    <></>
  );
};

export default Toast;
