/* eslint-disable no-unused-vars */
import { LOGIN_ERROR, UNKNOWN_ERROR } from "../utils/errors";
import { axiosInstance } from "../App";

export const login = async ({ queryKey }) => {
    const [_key, { username, password }] = queryKey;
    return axiosInstance
        .post(
            "auth/login",
            {
                username,
                password,
            },
            { withCredentials: true }
        )
        .then(({ data }) => data)
        .catch((err) => {
            throw new Error(
                LOGIN_ERROR[err?.response?.status]
                    ? LOGIN_ERROR[err?.response?.status]
                    : UNKNOWN_ERROR
            );
        });
};

export const forgotPassword = async ({ queryKey }) => {
    const [_key, { email }] = queryKey;
    return axiosInstance
        .post(
            "auth/forgot-password",
            {
                email
            },
            { withCredentials: true }
        )
        .then(({ data }) => data)
        .catch((err) => {
            throw new Error(err?.response?.data?.message || UNKNOWN_ERROR);
        });
};

export const resetPassword = async ({ queryKey }) => {
    const [_key, { password, confirmPassword, token }] = queryKey;
    return axiosInstance
        .post(
            "auth/reset-password",
            {
                password,
                token,
            },
            { withCredentials: true }
        )
        .then(({ data }) => data)
        .catch((err) => {
            throw new Error(err?.response?.data?.message || UNKNOWN_ERROR);
        });
};

export const getRefreshToken = async () => {
    const { data } = await axiosInstance.post(
        "auth/refreshToken",
        {},
        { withCredentials: true }
    );
    return data;
};

export const logout = async ({ queryKey }) => {
    const [_key, token] = queryKey;
    const { data } = await axiosInstance.get("auth/logout");

    return data;
};

