import { combineReducers } from "redux";
import types from "./types";

const defaultToast = {
  msg: null,
  severity: null,
};
const toast = (state = defaultToast, action) => {
  switch (action.type) {
    case types.UPDATE_TOAST: {
      return {
        msg: action.payload.msg,
        severity: action.payload.severity,
      };
    }
    case types.RESET_TOAST: {
      return defaultToast;
    }
    default:
      return state;
  }
};

export default combineReducers({
  toast,
});
