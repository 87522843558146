import { Checkbox, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import HeaderTitle from "./HeaderTitle";

const BooleanHeaderWithFilter = ({
  columnKey,
  columnName,
  filters,
  updateFilter,
  columnType,
  options,
  columnSubName,
}) => {
  const [filterCheckValue, setFilterCheckValue] = useState(true);
  const [isFilterActive, setIsFilterActive] = useState(false);

  const handleChangeFilter = (event) => {
    setFilterCheckValue(event.target.checked);
    updateFilter(
      columnKey,
      { type: columnType, value: event.target.checked },
      columnName
    );
  };

  const handleChangeIsActive = (event) => {
    if (event.target.checked) {
      setIsFilterActive(event.target.checked);
      setFilterCheckValue(filterCheckValue);
      updateFilter(
        columnKey,
        {
          type: columnType,
          value: filterCheckValue,
        },
        columnName
      );
    } else {
      setIsFilterActive(event.target.checked);
      updateFilter(columnKey, {
        type: columnType,
        value: null,
      });
    }
  };

  useEffect(() => {
    if (
      filters?.[columnKey] !== undefined &&
      filters?.[columnKey]?.value !== null
    ) {
      setIsFilterActive(true);
      setFilterCheckValue(filters[columnKey].value);
    } else {
      setIsFilterActive(false);
    }
  }, [columnKey, filters]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <HeaderTitle columnName={columnName} columnSubName={columnSubName} />
      <Checkbox
        style={{ padding: 0 }}
        checked={isFilterActive}
        onChange={handleChangeIsActive}
      />
      {isFilterActive && (
        <Switch checked={filterCheckValue} onChange={handleChangeFilter} />
      )}
    </div>
  );
};

export default BooleanHeaderWithFilter;
