import { connect } from "react-redux";

import { tabSelectors, tabActions } from "../store/tab";

import FilterResume from "../components/FilterResume";

export default connect(
  (state) => ({
    filters: tabSelectors.filtersTabSelected(state),
    tabSelected: tabSelectors.tabSelected(state),
    tabSelectedConfig: tabSelectors.tabSelectedConfig(state),
  }),
  (dispatch) => ({
    updateFilter: (filterKey, value, tabSelected, columnName) => {
      dispatch(
        tabActions.updateFilter(filterKey, value, tabSelected, columnName)
      );
    },
    resetFilters: (tabSelected) => {
      dispatch(tabActions.resetFilterByTab(tabSelected));
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
    updateFilter: (filterKey, value, columnName = null) => {
      dispatchToProps.updateFilter(
        filterKey,
        value,
        stateToProps.tabSelected,
        columnName
      );
    },
    resetFilters: () => {
      dispatchToProps.resetFilters(stateToProps.tabSelected);
    },
  })
)(FilterResume);
