export const COLONNE_TYPES = [
  {
    name: "Texte Libre",
    key: "text",
  },
  {
    name: "Multi-choix",
    key: "multi",
  },
  {
    name: "Personne",
    key: "person",
  },
  {
    name: "Oui/Non",
    key: "boolean",
  },
  {
    name: "Date",
    key: "date",
  },
  {
    name: "Ok/NA",
    key: "ok",
  },
];
