import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { StyledOption } from "../TabEditor/styles";
import HeaderTitle from "./HeaderTitle";
import { StyledFilterAutocomplete, StyledPopper } from "./styles";

const RoleHeaderWithFilter = ({
  columnKey,
  columnName,
  columnSubName,
  filters,
  updateFilter,
  users,
  columnType,
  allUsers = false,
}) => {
  const [filterValue, setFilterValue] = useState(null);
  const [filterInputValue, setFilterInputValue] = useState("");

  const location = useLocation();
  const handleChangeFilter = (value) => {
    updateFilter(columnKey, { type: columnType, value }, location, columnName);
    setFilterValue(value);
  };

  const handleChangeFilterInputValue = (value) => {
    updateFilter(columnKey, { type: columnType, value }, location, columnName);
    setFilterInputValue(value);
  };

  useEffect(() => {
    if (filters?.[columnKey]?.value) {
      setFilterInputValue(filters[columnKey].value);
      setFilterValue(filters[columnKey].value);
    } else {
      setFilterValue(null);
      setFilterInputValue("");
    }
  }, [columnKey, filters]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <HeaderTitle columnName={columnName} columnSubName={columnSubName} />

      <StyledFilterAutocomplete
        fullWidth
        classes={{ root: "styledRoot" }}
        freeSolo
        onChange={(event, newValue) => {
          handleChangeFilter(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          handleChangeFilterInputValue(newInputValue);
        }}
        inputValue={filterInputValue}
        forcePopupIcon={false}
        key={columnKey}
        id={columnKey}
        options={
          allUsers
            ? users.map((e) => e.username ?? "").concat([" "])
            : users
                .filter((user) => user.role === columnKey)
                .map((e) => e.username)
                .concat([" "])
        }
        value={filterValue}
        renderInput={(params) => {
          return <TextField {...params} fullWidth />;
        }}
        PopperComponent={(props) => (
          <StyledPopper {...props} placement="bottom" />
        )}
        renderOption={(props, option) => {
          return (
            <StyledOption
              {...props}
              option={option}
            >{`${option}`}</StyledOption>
          );
        }}
      />
    </div>
  );
};

export default RoleHeaderWithFilter;
