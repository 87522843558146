import { useQuery } from "react-query";

import {
    login,
    forgotPassword,
    resetPassword,
    getRefreshToken,
    logout,
} from "../../services/auth";

export const useLogin = (params, options) => {
    return useQuery(["login", { ...params }], login, {
        retry: false,
        ...options,
    });
};

export const useRefreshToken = (options) => {
    return useQuery(["refreshToken"], getRefreshToken, {
        retry: false,
        ...options,
    });
};

export const useLogout = (token, options) => {
    return useQuery(["logout", token], logout, {
        retry: false,
        ...options,
    });
};

export const useForgotPassword = (params, options) => {
    return useQuery(["forgotPassword", { ...params }], forgotPassword, {
        retry: false,
        ...options,
    });
}


export const useResetPassword = (params, options) => {
    return useQuery(["resetPassword", { ...params }], resetPassword, {
        retry: false,
        ...options,
    });
}
