import { connect } from "react-redux";

import { tabSelectors, tabActions } from "../store/tab";

import Dashboard from "../components/Dashboard";
import { userActions, userSelectors } from "../store/user";
import { clientSelectors } from "../store/client";
import { dashboardActions, dashboardSelectors } from "../store/dashboard";
import { getDashboardByYearAndCollab } from "../services/dashboard";
import { toastActions } from "../store/toast";

export default connect(
  (state) => ({
    isLoadingOnglets: tabSelectors.tabsLoading(state),
    tabsList: tabSelectors.tabsList(state),
    collabSelected: userSelectors.collabSelected(state),
    yearPicked: clientSelectors.yearSelected(state),
    isLoadingDashboard: dashboardSelectors.isLoadingDashboard(state),
    dashboardData: dashboardSelectors.dashboardData(state),
  }),
  (dispatch) => ({
    updateTabsConfig: (data) => {
      dispatch(tabActions.updateTabsConfig(data));
    },
    getDashboardByYearAndCollab: (year, collab) => {
      dispatch(dashboardActions.startGettingDashboard());
      getDashboardByYearAndCollab(year, collab)
        .then((res) => {
          dispatch(dashboardActions.updateDashboardData(res));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    updateCollabSelected: (collabSelected) => {
      dispatch(userActions.updateCollabSelected(collabSelected));
    },
  })
)(Dashboard);
