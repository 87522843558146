import React from "react";
import styled from "@emotion/styled";
import { Paper } from "@mui/material";

const StyledCard = styled(Paper)`
  display: flex;
  align-self: center;
  width: auto;
  flex-direction: column;
  align-items: center;
  max-height: 100%;
`;

const MyCard = ({ children }) => (
  <StyledCard elevation={3}>{children}</StyledCard>
);

export default MyCard;
