/* eslint-disable import/no-anonymous-default-export */
import types from "./types";

const updateUserList = (userList) => ({
  type: types.UPDATE_USER_LIST,
  payload: {
    userList,
  },
});

const startGettingUsers = () => ({
  type: types.IS_LOADING_USERS,
  payload: {},
});

const addUserToUserList = (user) => ({
  type: types.ADD_USER_TO_LIST,
  payload: {
    user,
  },
});

const deleteUserToUserList = (userID) => ({
  type: types.DELETE_USER_TO_LIST,
  payload: {
    userID,
  },
});

const updateCollabSelected = (collabSelected) => ({
  type: types.UPDATE_COLLAB_SELECTED,
  payload: {
    collabSelected,
  },
});

export default {
  startGettingUsers,
  updateUserList,
  addUserToUserList,
  updateCollabSelected,
  deleteUserToUserList,
};
