import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useForgotPassword } from "../../queries/auth";
import InputTextField from "../Widgets/InputTextField";
import { Alert, Box } from "@mui/material";
import Card from "../Widgets/Card";
import ButtonComponent from "../Widgets/Button";
import { StyledForgotPassword } from "./styles";
import MyForm from "../Widgets/Form";

const ForgotPassword = () => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Email requis"),
        }),
        onSubmit: () => {
            setIsFormValid(true);
        },
    });

    const { error } = useForgotPassword(formik.values, {
        enabled: isFormValid,
        onSuccess: (data) => {
            setIsFormValid(false);
            setSuccessMessage(data.message);
        },
    });

    useEffect(() => {
        setIsFormValid(false);
    }, [error]);

    return (
        <StyledForgotPassword>
            <Card>
                <Box padding='20px'>
                    <h2>Mot de passe oublié</h2>
                    <p>
                        Saisissez l'adresse e-mail associé à votre compte.
                    </p>
                    <MyForm onSubmit={formik.handleSubmit}>
                        <InputTextField
                            formik={formik}
                            formikItemKey="email"
                            label="Email"
                        />
                        <div>
                            <ButtonComponent submit title="Réinitialiser le mot de passe" />
                        </div>
                        {error && (
                            <Alert style={{ marginTop: "1rem" }} severity="error">
                                {error.message}
                            </Alert>
                        )}
                        {successMessage && (
                            <Alert style={{ marginTop: "1rem" }} severity="success">
                                {successMessage}
                            </Alert>
                        )}
                    </MyForm>
                </Box>

            </Card>
        </StyledForgotPassword>
    );
};

export default ForgotPassword;
