import { AppBar, Toolbar } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../context/user/UserContext";
import Logout from "../Logout";
import MenuAppBar from "./MenuAppBar";
import VirtuozLogo from "../../assets/img/Virtuoz_logo.png";
import { useNavigate } from "react-router-dom";
import { HOME } from "../../routes/utils";
import { StyledLogo } from "./styles";

const MyAppBar = ({ tabsList }) => {
  const navigate = useNavigate();
  const [userContext] = useContext(UserContext);

  return (
    <AppBar position="static" color="transparent">
      <Toolbar style={{ justifyContent: "space-between" }}>
        <StyledLogo
          src={VirtuozLogo}
          alt="VirtuozLogo"
          onClick={() => navigate(HOME)}
        />
        {userContext.token && (
          <MenuAppBar userContext={userContext} tabsList={tabsList} />
        )}
        {userContext.token && <Logout />}
      </Toolbar>
    </AppBar>
  );
};

export default MyAppBar;
