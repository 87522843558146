import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import appReducer from "./reducers";

export default compose(applyMiddleware(thunk))(createStore)(
  appReducer,
  process.env.REACT_APP_PERSO_ENV !== "production" &&
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
);
