import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledAutocomplete, StyledOption } from "./styles";

const MultiAction = ({ onChange, options, defaultValue, width }) => {
    const [multiValue, setMultiValue] = useState("");
    const [highLightValue, setHightLightValue] = useState(null);

    useEffect(() => {
        if (defaultValue) {
            setMultiValue(defaultValue);
        }
    }, [defaultValue]);

    const handleChangeTextInputEnter = (value, e) => {
        setMultiValue(value);
        onChange(value)
        e.stopPropagation();
    };

    const handleChangeOnClick = (value) => {
        setMultiValue(value);
        onChange(value)
    };

    return (
        <StyledAutocomplete
            forcePopupIcon={false}
            classes={{ root: "styledRoot" }}
            key='multi'
            id='multi'
            style={{ width: width ?? '100%' }}
            autoFocus
            options={options}
            value={multiValue}
            disableClearable
            onHighlightChange={(e, value) => {
                setHightLightValue(value);
            }}
            openOnFocus
            onChange={(e, values) => {
                handleChangeOnClick(values);
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                                if (highLightValue !== null) {
                                    e.stopPropagation();
                                    handleChangeTextInputEnter(highLightValue, e);
                                }
                            }
                        }}
                        {...params}
                        fullWidth
                    />
                );
            }}
            renderOption={(props, option) => {
                return (
                    <StyledOption {...props} option={option}>{`${option}`}</StyledOption>
                );
            }}
        />
    );
};

export default MultiAction;
