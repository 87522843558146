import { useFormik } from "formik";
import { useMutation } from "react-query";
import AddEditUserComponent from ".";
import { updateUser } from "../../services/user";
import * as Yup from "yup";
import MyModal from "../Widgets/Modal";

const EditUser = ({
  roles = [],
  user = {},
  isModalOpen,
  setModalOpen,
  updateUserToUserList,
}) => {
  const formik = useFormik({
    initialValues: {
      username: user?.username,
      email: user?.email,
      lastName: user?.lastName,
      firstName: user?.firstName,
      role: user?.roleID,
      password: null,
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required("Nom requis"),
      firstName: Yup.string().required("Prénom requis"),
      username: Yup.string().required("Pseudo requis"),
      role: Yup.string().required("Role requis"),
      password: Yup.string(),
    }),
  });

  const mutation = useMutation(
    (params) => {
      updateUser({ ...params, _id: user?._id });
    },
    {
      onSuccess: () => {
        setModalOpen(false);
        updateUserToUserList({ ...formik.values, _id: user._id });
        formik.resetForm();
      },
    }
  );

  const handleUpdateUser = () => {
    mutation.mutate(formik.values);
  };

  return (
    <MyModal
      title={`Modification de l'utilisateur ${user.firstName} ${user.lastName}`}
      open={isModalOpen}
      handleCancel={() => {
        setModalOpen(false);
      }}
      handleValidate={handleUpdateUser}
    >
      <AddEditUserComponent roles={roles} formik={formik} isEditMode />
    </MyModal>
  );
};

export default EditUser;
