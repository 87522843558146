import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const MyModal = ({
  title,
  open,
  handleCancel,
  handleValidate,
  children,
  displayButtons = true,
}) => (
  <Dialog open={open} onClose={handleCancel}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    {displayButtons && (
      <DialogActions>
        <Button onClick={handleCancel}>Annuler</Button>
        <Button onClick={handleValidate}>Valider</Button>
      </DialogActions>
    )}
  </Dialog>
);

export default MyModal;
