/* eslint-disable no-unused-vars */
import { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import ButtonComponent from "../Widgets/Button";
import { UserContext } from "../../context/user/UserContext";
import { Alert, Box } from "@mui/material";
import { StyledGlobalDivTabData } from "./styles";
import { useTheme } from "@mui/material";
import { checkRightFilter } from "../TabHeaders/utils";
import SettingsIcon from "@mui/icons-material/Settings";
import SaveIcon from "@mui/icons-material/Save";
import FilterResumeContainer from "../../containers/FilterResumeContainer";
import ClientTotal from "../ClientTotal";
import YearPickerContainer from "../../containers/YearPickerContainer";
import CSVExport from "../Widgets/ExportComponent";
import DataGrid, { SelectColumn } from "react-data-grid";
import UpdateSelectedRowsModal from "../UpdateSelectedRows/UpdateSelectedRowsModal";


const TabData = ({
  tabSelectedColumnHeader,
  tabSelectedRows,
  tabSelectedConfig,
  updateColumns,
  updateColumnsData,
  yearSelected,
  filters,
  handleSwitchMode,
  handleCopyPasteDifferentType,
}) => {

  const [yearPicked, setYearPicked] = useState(new Date().getFullYear());

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [userContext] = useContext(UserContext);
  const [sortColumns, setSortColumns] = useState([]);
  const [columnsUpdatedRows, setColumnsUpdatedRows] = useState({});
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [isRowModified, setIsRowModified] = useState(false);
  const [isApplyActionsRowsModalOpen, setIsApplyActionsRowsModalOpen] = useState(false);

  const filteredRows = useMemo(() => {
    if (filters) {
      return rows.filter((r) => {
        return Object.keys(filters)
          .map((filterKey) => {
            return checkRightFilter(
              filters[filterKey].type,
              r[filterKey],
              filters,
              filterKey
            );
          })
          .every((i) => i);
      });
    } else {
      return rows;
    }
  }, [rows, filters]);

  useEffect(() => {
    setYearPicked(yearSelected);
  }, [yearSelected]);

  useEffect(() => {
    const cols = (
      tabSelectedColumnHeader.map((column) => ({
        ...column,
        editable: column.admin ? false : true,
        minWidth: column.name.length * 10,
      }))
    );
    setColumns([SelectColumn, ...cols]);
  }, [
    setColumns,
    tabSelectedColumnHeader,
  ]);

  useEffect(() => {
    setRows(tabSelectedRows);
  }, [tabSelectedRows, setRows]);

  const handleRowsChange = (rows, data) => {
    setRows(rows);
    setColumnsUpdatedRows({
      ...columnsUpdatedRows,
      [rows[data.indexes[0]].id]: {
        ...rows[data.indexes[0]],
        year: yearPicked,
      },
    });
  };

  useEffect(() => {
    if (Object.keys(columnsUpdatedRows).length > 0) {
      setIsRowModified(true);
    }
  }, [columnsUpdatedRows]);

  const handleEditColumnsClick = () => {
    updateColumns(columnsUpdatedRows).then(() => {
      setColumnsUpdatedRows({});
    });
    setIsRowModified(false);
  };

  const handlePaste = ({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) => {
    if (
      tabSelectedConfig.columns[sourceColumnKey].type !==
      tabSelectedConfig.columns[targetColumnKey].type
    ) {
      handleCopyPasteDifferentType();
      return targetRow;
    } else {
      return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }
  };

  const theme = useTheme();

  return (
    <StyledGlobalDivTabData theme={theme} isAdmin={userContext.profile.isAdmin}>
      <Box
        minHeight="95px"
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
      >
        {userContext.profile.isAdmin && (
          <ButtonComponent
            variant="contained"
            endIcon={<SettingsIcon />}
            title="Mode configuration"
            onClickMethod={handleSwitchMode}
          />
        )}
        <div>
          {!tabSelectedConfig?.visibility && (
            <Alert severity="error">
              Attention cet onglet n'est actuellement pas visible
            </Alert>
          )}
          <FilterResumeContainer />
        </div>


        <Box display='flex' alignItems='center' gap='10px'>
          {selectedRows.size > 0 && (
            <ButtonComponent
              title={`Modifier ${selectedRows.size} lignes sélectionnées`}
              onClickMethod={() => setIsApplyActionsRowsModalOpen(true)}
            />
          )}
          {isRowModified && (
            <ButtonComponent
              title={"Sauvegarder"}
              onClickMethod={handleEditColumnsClick}
              endIcon={<SaveIcon />}
            />
          )}
          {!isRowModified && (
            <>
              <CSVExport
                columns={columns}
                rows={filteredRows}
                fileName={tabSelectedConfig?.id}
              />
              {!tabSelectedConfig?.isFrozenInTime && (
                <Box marginTop='1rem'>
                  <YearPickerContainer />
                </Box>
              )}
            </>
          )}
        </Box>

      </Box>
      <div style={{ width: "100%", height: "92vh" }}>
        <DataGrid
          style={{
            height: '90%',
            color: theme.palette.internalPrimary.main
          }}
          className="rdg-light"
          onPaste={handlePaste}
          headerRowHeight={100}
          rowKeyGetter={(row) => row.id}
          isVisible={tabSelectedConfig?.visibility}
          columns={columns}
          rows={filteredRows}
          onRowsChange={handleRowsChange}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
            filterable: true,
          }}
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
        />
        <ClientTotal clientsNumber={filteredRows.length} />
      </div>
      <UpdateSelectedRowsModal
        isModalOpen={isApplyActionsRowsModalOpen}
        rowsCount={selectedRows.size}
        onClose={() => setIsApplyActionsRowsModalOpen(false)}
        onSubmit={(values) => {
          updateColumnsData(Array.from(selectedRows), values).then(() => {
            setIsApplyActionsRowsModalOpen(false);
          });
        }}
        columns={columns}
        tabConfig={tabSelectedConfig}
      />
    </StyledGlobalDivTabData>
  );
};
export default TabData;
