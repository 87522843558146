import { connect } from "react-redux";

import GestionUtilisateursComponent from "../components/GestionUtilisateurs";
import { getClientList } from "../services/client";
import { clientActions } from "../store/client";
import { toastActions } from "../store/toast";
import { userSelectors, userActions } from "../store/user";

export default connect(
  (state) => ({
    isLoadingUsers: userSelectors.isLoadingUsers(state),
    userList: userSelectors.userList(state),
  }),
  (dispatch) => ({
    updateUserList: (users) => {
      dispatch(userActions.updateUserList(users));
    },
    startUpdatingUserList: () => {
      dispatch(userActions.startGettingUsers());
    },
    updateToast: (msg) => {
      dispatch(toastActions.updateToast(msg, "success"));
    },
    getClientList: () => {
      dispatch(clientActions.startGettingClients());
      getClientList()
        .then((data) => {
          dispatch(clientActions.updateClientList(data));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
    updateUserToUserList: (userUpdated) => {
      dispatchToProps.startUpdatingUserList();
      dispatchToProps.updateToast(
        `L'utilisateur ${userUpdated.firstName} ${userUpdated.lastName} mis à jour avec succès`
      );
      const newUserList = stateToProps.userList.map((user) =>
        user._id === userUpdated._id ? { ...user, ...userUpdated } : user
      );
      dispatchToProps.updateUserList(newUserList);
      dispatchToProps.getClientList();
    },
  })
)(GestionUtilisateursComponent);
