import { TextField } from "@mui/material";

const InputTextField = ({ formik, formikItemKey, type, label }) => {
  return (
    <TextField
      type={type}
      fullWidth
      id={formikItemKey}
      name={formikItemKey}
      label={label}
      value={formik.values[formikItemKey]}
      onChange={formik.handleChange}
      error={
        formik.touched[formikItemKey] && Boolean(formik.errors[formikItemKey])
      }
      helperText={formik.touched[formikItemKey] && formik.errors[formikItemKey]}
    />
  );
};

export default InputTextField;
