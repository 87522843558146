/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import MyModal from "../Widgets/Modal";
import { useRoles, useUsers } from "../../queries/user";
import MyForm from "../Widgets/Form";

import {
    Box,
    Typography,
    Autocomplete,
    FormControl,
    FormGroup,
    FormLabel,
    TextField,
} from "@mui/material";
import MultiAction from "../TabActions/MultiAction";

const EditClientsBulkModal = ({
    rowsCount,
    isModalOpen,
    onClose,
    onSubmit,
    tabsList,
}) => {

    const [selectedRoles, setSelectedRoles] = useState({});
    const [selectedOnglets, setSelectedOnglets] = useState([]);
    const { data: users = [] } = useUsers();
    const { data: roles = [], error } = useRoles();


    const handleClose = () => {
        setSelectedRoles({});
        setSelectedOnglets([]);
        onClose();
    }

    const handleSubmit = () => {
        onSubmit({ selectedRoles, selectedOnglets });
        handleClose();
    }

    const handleChangeMultiple = async (event, values, roleKey) => {
        setSelectedRoles(prev => ({ ...prev, [roleKey]: values }));
    };

    const handleChangeOnglet = (onglet, value) => {
        // ajoute l'onglet si il n'est pas présent dans la liste des onglets sélectionnés
        const isInSelectedOnglets = selectedOnglets.find((value) => {
            return value.id === onglet.id;
        });

        if (!isInSelectedOnglets && value !== "Non modifié") {
            setSelectedOnglets(prev => [...prev, { id: onglet.id, value }]);
            return;
        }
        // supprime l'onglet si il est présent dans la liste des onglets sélectionnés
        if (isInSelectedOnglets && value === "Non modifié") {
            setSelectedOnglets(
                selectedOnglets.filter((value) => {
                    return value.id !== onglet.id;
                })
            );
            return;
        }

        if (isInSelectedOnglets && value !== "Non modifié") {
            setSelectedOnglets(prev =>
                prev.map((selectedOnglet) => {
                    if (onglet.id === selectedOnglet.id) {
                        return { id: onglet.id, value };
                    }
                    return selectedOnglet;
                })
            );
            return;
        }
    }

    return (
        <MyModal
            title={`Modifier ${rowsCount} lignes`}
            open={isModalOpen}
            handleCancel={handleClose}
            handleValidate={() => {
                handleSubmit();
            }}
        >
            <Typography as='p'>
                Attention, vous allez modifier {rowsCount} clients. Les actions suivantes seront appliquées à toutes les clients sélectionnées.
            </Typography>
            <Typography as='p' mt='5px'>
                Si vous sélectionnez au moins un onglet, les onglets des clients sélectionnés seront remplacés par ceux que vous avez sélectionnés.
            </Typography>
            <Typography as='p' mt="5px">
                Les rôles des clients sélectionnés seront remplacés par ceux que vous modifiés uniquement
            </Typography>
            <Box
                marginTop='20px'
                display='flex'
                flexDirection='column'
            >

                <MyForm onSubmit={handleSubmit}>
                    {roles.map((role) => {
                        return (
                            <Autocomplete
                                value={selectedRoles[role.id] ?? []}
                                key={role.id}
                                multiple
                                id={role.id}
                                filterSelectedOptions
                                options={users.filter((user) => user.role === role.id)}
                                isOptionEqualToValue={(option, val) => option._id === val._id}
                                getOptionLabel={(option) => `${option.username}`}
                                onChange={(e, values) => {
                                    handleChangeMultiple(e, values, role.id);
                                }}
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            label={role.name}
                                            placeholder="Sacha"
                                        />
                                    );
                                }}
                            />
                        );
                    })}
                    <FormControl error={error} fullWidth>
                        <FormLabel>Présents dans les onglets: </FormLabel>
                        <FormGroup>
                            {tabsList.map((onglet) => (
                                <Box display='flex' justifyContent='space-between' width='100%' my='5px'>
                                    <Typography as='p'>
                                        {onglet.name}
                                    </Typography>
                                    <MultiAction
                                        width='50%'
                                        defaultValue='Non modifié'
                                        options={['Non modifié', 'Oui', 'Non']}
                                        onChange={(value) => {
                                            handleChangeOnglet(onglet, value)
                                        }}
                                    />
                                </Box>

                            ))}
                        </FormGroup>
                    </FormControl>
                </MyForm>
            </Box>
        </MyModal>
    );
};

export default EditClientsBulkModal;
