import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { updateTabConfig } from "../../services/tab";
import ButtonComponent from "../Widgets/Button";

const EditVisibility = ({ tabConfig, tabSelected, getOngletsConfig }) => {
  const [visibility, setVisibility] = useState(false);
  const [dashboardVisibility, setDashboardVisibility] = useState(false);
  const [isFrozenInTime, setIsFrozenInTime] = useState(false);

  useEffect(() => {
    if (tabConfig) {
      setVisibility(tabConfig.visibility);
      setDashboardVisibility(tabConfig.dashboardVisibility);
      setIsFrozenInTime(tabConfig.isFrozenInTime ?? false);
    }
  }, [tabConfig, tabSelected]);

  const mutationConfig = useMutation(
    () => updateTabConfig({
      visibility,
      dashboardVisibility,
      tabID: tabSelected,
      isFrozenInTime
    }),
    {
      onSuccess: () => {
        getOngletsConfig();
      },
      onError: () => { },
    }
  );

  const switchVisibility = (e) => {
    setVisibility(e.target.checked);
  };

  const switchDashboardVisibility = (e) => {
    setDashboardVisibility(e.target.checked);
  };

  const switchFrozenInTime = (e) => {
    setIsFrozenInTime(e.target.checked);
  }

  const handleSubmit = () => {
    mutationConfig.mutate();
  };

  const hasChanged = visibility !== tabConfig.visibility ||
    dashboardVisibility !== tabConfig.dashboardVisibility ||
    isFrozenInTime !== (tabConfig.isFrozenInTime ?? false);

  return (
    <>
      {tabConfig.visibility !== null && (
        <>
          <div>
            Visibilité de l'onglet
            <Switch
              checked={visibility}
              onChange={switchVisibility}
              color="secondary"
            />
          </div>
          <div>
            Visibilité de l'onglet dans le dashboard
            <Switch
              checked={dashboardVisibility}
              onChange={switchDashboardVisibility}
              color="secondary"
            />
          </div>
          <div>
            Est figé dans le temps
            <Switch
              checked={isFrozenInTime}
              onChange={switchFrozenInTime}
              color="secondary"
            />
          </div>
          {hasChanged && (
            <ButtonComponent
              color="secondary"
              variant="contained"
              title="Valider"
              onClickMethod={handleSubmit}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditVisibility;
