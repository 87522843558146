import styled from "styled-components";

export const StyledTextFieldList = styled("div")`
  display: flex;
  flex-direction: column;
  & > * {
    margin-top: 1rem;
  }
  padding: 2vh 0;
  /* grid-column-start: 1;
  grid-column-end: 10; */
`;

export const StyledConfigDiv = styled("div")`
  min-width: 7vw;
  max-width: 7vw;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: #e0f2f1;
  align-items: center;
  min-height: 70px;
  text-align: center;
  justify-content: center;
  :hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.palette.internalPrimary.main};
    color: ${({ theme }) => theme.palette.internalPrimary.contrastText};
  }
`;

export const StyledModuleConfig = styled("div")`
  border: 1px solid ${({ theme }) => theme.palette.secondary.main};
  border-radius: 10px;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  height: max-content;
`;
