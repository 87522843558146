import { axiosInstance } from "../App";

export const getOnglets = () => {
  return axiosInstance.get("sys/onglets", {}).then((response) => response.data);
};

export const getGroupes = () => {
  return axiosInstance.get(`sys/groupes`, {}).then((response) => {
    const finalGroupes = response.data.filter((i) => i !== "N/A");
    finalGroupes.push("N/A");
    return finalGroupes;
  });
};
