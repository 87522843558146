import { Box, TextField } from "@mui/material";
import { useState } from "react";
import DatePicker from "react-datepicker";

const DateAction = ({ onChange }) => {
    const [value, setValue] = useState(null);

    const handleChangeDate = (date) => {
        setValue(date);
        onChange(date)
    };

    return (
        <Box display='flex' justifyContent='center'>
            <DatePicker
                className='date-picker'
                customInput={<TextField fullWidth />}
                // popperContainer={({ children }) => createPortal(children, document.body)}
                selected={value}
                onChange={handleChangeDate}
                dateFormat="dd-MM"
            />
        </Box>
    );
};

export default DateAction;
