import { css } from "@emotion/css";
import styled from "styled-components";

export const StyledAdminDiv = css`
  background-color: #f2f8ff;
  font-weight: 600;
`;

export const StyledTextCell = styled("div")`
`;

// const chooseRightBackgroundColor = (cellContent) => {
//   switch (cellContent) {
//     case "N/A":
//       return "#CDCDCD";
//     case null:
//     case undefined:
//     case "":
//     case " ":
//       return "transparent";
//     default:
//       return "#9DFFCA";
//   }
// };
