import React, { useContext, useEffect } from "react";
import { StyledDiv, StyledMain } from "./styles.js";
import MyAppBar from "./AppBar";
import { CircularProgress, useTheme } from "@mui/material";
import { UserContext } from "../../context/user/UserContext.js";
import { useLocation } from "react-router-dom";

const GlobalWrapper = ({
  children,
  getOngletsConfig,
  getClientList,
  getUserList,
  isLoadingOnglets,
  tabsList,
  updateRouteToStore,
}) => {
  const theme = useTheme();
  const [userContext] = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    updateRouteToStore(location.pathname);
  }, [location, updateRouteToStore]);

  useEffect(() => {
    if (userContext.profile) {
      getOngletsConfig();
      getClientList();
      getUserList();
    }
  }, [getOngletsConfig, getClientList, userContext, getUserList]);

  return isLoadingOnglets ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  ) : (
    <StyledDiv>
      <MyAppBar tabsList={tabsList} />
      <StyledMain theme={theme}>{children}</StyledMain>
    </StyledDiv>
  );
};

export default GlobalWrapper;
