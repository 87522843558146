import { useTheme } from "@mui/material";
import styled from "styled-components";

const StyledDiv = styled("div")`
  height: 10%;
  color: ${({ theme }) => theme.palette.internalPrimary.main};
`;

const ClientTotal = ({ clientsNumber }) => {
  const theme = useTheme();
  return (
    <StyledDiv theme={theme}>
      <span
        style={{
          display: "flex",
          marginTop: "1vh",
          justifyContent: "center",
          fontWeight: 700,
        }}
      >
        Clients affichés: {clientsNumber}
      </span>
    </StyledDiv>
  );
};

export default ClientTotal;
