/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";

import { Box, CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ButtonComponent from "../Widgets/Button";
import { AddOngletModal } from "./AddOngletModal";
import { useSelector } from "react-redux";
import { tabSelectors } from "../../store/tab";
import { EditOngletModal } from "./EditOngletModal";
import { DeleteOngletModal } from "./DeleteOngletModal";

const GestionOngletsComponent = () => {
  const isLoading = useSelector(tabSelectors.tabsLoading);
  const tabsList = useSelector(tabSelectors.tabsList);
  const [isAddingOnglet, setIsAddingOnglet] = useState(false);
  const [isEditingOnglet, setIsEditingOnglet] = useState(false);
  const [isDeletingOnglet, setIsDeletingOnglet] = useState(false);
  const [selectedOnglet, setSelectedOnglet] = useState(null);

  const handleEditRowClick = (onglet) => {
    setSelectedOnglet(onglet);
    setIsEditingOnglet(true);
  }

  const handleDeleteRowClick = (onglet) => {
    setSelectedOnglet(onglet);
    setIsDeletingOnglet(true);
  }

  return (
    <Box display='flex' flexDirection='column' width='100vw' margin='0 5rem'>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <h1>Liste des onglets</h1>
        <ButtonComponent
          title="Ajouter un onglet"
          variant="contained"
          disabled={isAddingOnglet}
          onClickMethod={() => setIsAddingOnglet(true)}
        />
      </div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box padding='2rem 15rem'>
            {tabsList?.map((onglet) => (
              <Box
                key={onglet.id}
                display="flex"
                justifyContent="space-between"
                alignIitems='center'
                border='1px solid aliceblue'
                padding='1rem 2rem'
                fontSize='large'
                fontWeight='500'
                sx={{ ":hover": { backgroundColor: "#1976d2", color: "white", cursor: "pointer" } }}
                onClick={() => handleEditRowClick(onglet)}
              >
                <span>{onglet.name}</span>
                <Box
                  width='20%'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <IconButton
                    aria-label="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteRowClick(onglet);
                    }}
                  >
                    <DeleteIcon color="error" variant="contained" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      <AddOngletModal
        isOpen={isAddingOnglet}
        onClose={() => setIsAddingOnglet(false)}
      />
      {isEditingOnglet && (
        <EditOngletModal
          isOpen
          onglet={selectedOnglet}
          onClose={() => {
            setIsEditingOnglet(false)
            setSelectedOnglet(null)
          }}
        />
      )}

      {isDeletingOnglet && (
        <DeleteOngletModal
          onglet={selectedOnglet}
          isOpen={isDeletingOnglet}
          onClose={() => {
            setIsDeletingOnglet(false)
            setSelectedOnglet(null)
          }}
        />
      )}
    </Box>
  );


};

export default GestionOngletsComponent;
