import { createDuck } from "redux-duck";
import RoleChangeEditorContainer from "../../containers/TabEditor/RoleChangeEditorContainer";
import DateEditor from "../../components/TabEditor/DateEditor";
import TextInputEditor from "../../components/TabEditor/TextInputEditor";
import { format } from "date-fns";
import TextFormatter from "../../components/TabFormatters/TextFormatter";
import DateFormatter from "../../components/TabFormatters/DateFormatter";
import { StyledAdminDiv } from "../../components/TabFormatters/style";
import PersonFormatter from "../../components/TabFormatters/PersonFormatter";
import TextHeaderWithFilterContainer from "../../containers/TabHeaders/TextHeaderWithFilterContainer";
import RoleHeaderWithFilterContainer from "../../containers/TabHeaders/RoleHeaderWithFilterContainer";
import MultiEditor from "../../components/TabEditor/MultiEditor";
import BooleanEditor from "../../components/TabEditor/BooleanEditor";
import BooleanHeaderFilterContainer from "../../containers/TabHeaders/BooleanHeaderFilterContainer";
import MultiAutoCompleteCheckboxHeaderFilterContainer from "../../containers/TabHeaders/MultiAutoCompleteCheckboxHeaderFilterContainer";

export const tabs = createDuck("tabs");

export const CLIENTS_HEADER = (users, clientContent = []) => {
  return [
    {
      key: "name",
      name: "Societe",
      type: "text",
      admin: true,
      resizable: true,
      width: 150,
      frozen: true,
      cellClass(row) {
        return StyledAdminDiv;
      },
      headerRenderer: (p) => (
        <TextHeaderWithFilterContainer
          columnType="text"
          columnName={p.column.name}
          columnKey={p.column.key}
        />
      ),
    },
    {
      key: "groupe_attached",
      name: "Groupe",
      type: "text",
      admin: true,
      resizable: true,
      width: 120,
      cellClass(row) {
        return StyledAdminDiv;
      },
      formatter: ({ column, row }) => (
        <TextFormatter column={column} row={row} />
      ),
      headerRenderer: (p) => (
        <TextHeaderWithFilterContainer
          columnType="text"
          columnName={p.column.name}
          columnKey={p.column.key}
        />
      ),
    },
    {
      key: "ASSOCIATE",
      name: "Associé",
      type: "person",
      admin: true,
      resizable: true,
      cellClass(row) {
        return StyledAdminDiv;
      },
      width: 150,
      formatter: ({ column, row }) => {
        return <PersonFormatter persons={row[column.key]} users={users} />;
      },
      editor: RoleChangeEditorContainer,
      headerRenderer: (p) => (
        <RoleHeaderWithFilterContainer
          columnType="person"
          columnName={p.column.name}
          columnKey={p.column.key}
        />
      ),
      editorOptions: {
        onNavigation(e) {
          const { key } = e;
          if (key === "Tab") {
            return true;
          }
          return false;
        },
        editOnClick: true,
        commitOnOutsideClick: true,
      },
    },
    {
      key: "DIRECTOR",
      name: "Directeur",
      type: "person",
      admin: true,
      resizable: true,
      width: 150,
      cellClass(row) {
        return StyledAdminDiv;
      },
      formatter: ({ column, row }) => {
        return <PersonFormatter persons={row[column.key]} users={users} />;
      },
      editor: RoleChangeEditorContainer,
      headerRenderer: (p) => (
        <RoleHeaderWithFilterContainer
          columnType="person"
          columnName={p.column.name}
          columnKey={p.column.key}
        />
      ),
      editorOptions: {
        onNavigation(e) {
          const { key } = e;
          if (key === "Tab") {
            return true;
          }
          return false;
        },
        editOnClick: true,
        commitOnOutsideClick: true,
      },
    },
    {
      key: "MANAGER",
      name: "Manager",
      type: "person",
      admin: true,
      resizable: true,
      width: 150,
      cellClass(row) {
        return StyledAdminDiv;
      },
      formatter: ({ column, row }) => {
        return <PersonFormatter persons={row[column.key]} users={users} />;
      },
      editor: RoleChangeEditorContainer,
      headerRenderer: (p) => (
        <RoleHeaderWithFilterContainer
          columnType="person"
          columnName={p.column.name}
          columnKey={p.column.key}
        />
      ),
      editorOptions: {
        onNavigation(e) {
          const { key } = e;
          if (key === "Tab") {
            return true;
          }
          return false;
        },
        editOnClick: true,
        commitOnOutsideClick: true,
      },
    },
    {
      key: "CHEF_MISSION",
      name: "Chef de Mission",
      type: "person",
      admin: true,
      resizable: true,
      width: 150,
      cellClass(row) {
        return StyledAdminDiv;
      },
      formatter: ({ column, row }) => {
        return <PersonFormatter persons={row[column.key]} users={users} />;
      },
      editor: RoleChangeEditorContainer,
      headerRenderer: (p) => (
        <RoleHeaderWithFilterContainer
          columnType="person"
          columnName={p.column.name}
          columnKey={p.column.key}
        />
      ),
      editorOptions: {
        onNavigation(e) {
          const { key } = e;
          if (key === "Tab") {
            return true;
          }
          return false;
        },
        editOnClick: true,
        commitOnOutsideClick: true,
      },
    },
    {
      key: "SUPERVISOR",
      name: "Superviseur",
      type: "person",
      admin: true,
      resizable: true,
      width: 150,
      cellClass(row) {
        return StyledAdminDiv;
      },
      formatter: ({ column, row }) => {
        return <PersonFormatter persons={row[column.key]} users={users} />;
      },
      editor: RoleChangeEditorContainer,
      headerRenderer: (p) => (
        <RoleHeaderWithFilterContainer
          columnType="person"
          columnName={p.column.name}
          columnKey={p.column.key}
        />
      ),
      editorOptions: {
        onNavigation(e) {
          const { key } = e;
          if (key === "Tab") {
            return true;
          }
          return false;
        },
        editOnClick: true,
        commitOnOutsideClick: true,
      },
    },
    {
      key: "COLLABORATOR",
      name: "Collaborateur",
      type: "person",
      admin: true,
      resizable: true,
      width: 150,
      cellClass(row) {
        return StyledAdminDiv;
      },
      formatter: ({ column, row }) => {
        return <PersonFormatter persons={row[column.key]} users={users} />;
      },
      editor: RoleChangeEditorContainer,
      headerRenderer: (p) => (
        <RoleHeaderWithFilterContainer
          columnType="person"
          columnName={p.column.name}
          columnKey={p.column.key}
        />
      ),
      editorOptions: {
        onNavigation(e) {
          const { key } = e;
          if (key === "Tab") {
            return true;
          }
          return false;
        },
        editOnClick: true,
        commitOnOutsideClick: true,
      },
    },
    {
      key: "date_cloture_bilan",
      name: "Date de Cloture",
      type: "date",
      admin: true,
      resizable: true,
      width: 150,
      cellClass(row) {
        return StyledAdminDiv;
      },
      formatter: ({ column, row }) => (
        <DateFormatter column={column} row={row} />
      ),
      headerRenderer: (p) => {
        return chooseRightHeaderFilterByColumnType(
          "date",
          p,
          null,
          clientContent
        );
      },
      editor: DateEditor,
      editorOptions: {
        onNavigation(e) {
          const { key } = e;
          if (key === "Tab") {
            return true;
          }
          return false;
        },
        editOnClick: true,
        commitOnOutsideClick: true,
      },
    },
  ];
};

export const chooseRightEditorByColumnType = (
  columnType,
  users,
  props,
  options = [""]
) => {
  switch (columnType) {
    case "text":
      return <TextInputEditor {...props} />;
    case "person":
      return <RoleChangeEditorContainer allUsers {...props} users={users} />;
    case "ok":
      return <MultiEditor options={["OK", "N/A", ""]} {...props} />;
    case "multi":
      return (
        <MultiEditor
          options={[...options?.filter((opt) => opt.trim().length !== 0), ""]}
          {...props}
        />
      );
    case "boolean":
      return <BooleanEditor {...props} />;
    case "date":
      return <DateEditor {...props} />;
    default:
      return <TextInputEditor {...props} />;
  }
};

export const chooseRightFormatterByColumnType = (columnType, users, props) => {
  switch (columnType) {
    case "text":
      return <TextFormatter {...props} />;
    case "person":
      return (
        <PersonFormatter persons={props.row[props.column.key]} users={users} />
      );
    case "ok":
      return <TextFormatter {...props} />;
    case "multi":
      return <TextFormatter {...props} />;
    case "boolean":
      return <BooleanEditor {...props} disabled />;
    case "date":
      return <DateFormatter column={props.column} row={props.row} />;
    default:
      return <TextFormatter {...props} />;
  }
};

export const chooseRightHeaderFilterByColumnType = (
  columnType,
  props,
  options = [""],
  content = []
) => {
  switch (columnType) {
    case "text":
      return (
        <TextHeaderWithFilterContainer
          columnType="text"
          columnName={props.column.name}
          columnSubName={props.column.subName}
          columnKey={props.column.key}
        />
      );
    case "person":
      return (
        <RoleHeaderWithFilterContainer
          columnType="person"
          columnName={props.column.name}
          columnKey={props.column.key}
          columnSubName={props.column.subName}
          allUsers
        />
      );
    case "ok":
      return (
        <MultiAutoCompleteCheckboxHeaderFilterContainer
          columnType="ok"
          columnName={props.column.name}
          columnKey={props.column.key}
          columnSubName={props.column.subName}
          options={["OK", "N/A", ""]}
        />
      );
    case "multi":
      return (
        <MultiAutoCompleteCheckboxHeaderFilterContainer
          columnType="multi"
          columnName={props.column.name}
          columnSubName={props.column.subName}
          columnKey={props.column.key}
          options={[...options.filter((i) => i.trim().length > 0), ""]}
        />
      );
    case "boolean":
      return (
        <BooleanHeaderFilterContainer
          columnType="boolean"
          columnName={props.column.name}
          columnSubName={props.column.subName}
          columnKey={props.column.key}
        />
      );
    case "date":
      const temp = [
        ...new Set(
          content
            .map((i) => {
              return i[props.column.key]
                ? format(new Date(i[props.column.key]), "dd-MM")
                : null;
            })
            .filter((i) => i)
        ),
      ];
      return (
        <MultiAutoCompleteCheckboxHeaderFilterContainer
          columnType="date"
          columnName={props.column.name}
          columnSubName={props.column.subName}
          columnKey={props.column.key}
          options={[...temp, ""]}
        />
      );
    default:
      return TextFormatter;
  }
};
