import { combineReducers } from "redux";
import types from "./types";

const isLoadingDashboard = (state = false, action) => {
  switch (action.type) {
    case types.IS_LOADING_DASHBOARD: {
      return true;
    }
    case types.UPDATE_DASHBOARD_DATA: {
      return false;
    }
    default:
      return state;
  }
};

const dashboardData = (state = [], action) => {
  switch (action.type) {
    case types.UPDATE_DASHBOARD_DATA: {
      return action.payload.data;
    }
    default:
      return state;
  }
};

export default combineReducers({
  isLoadingDashboard,
  dashboardData,
});
