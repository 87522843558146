import { Switch } from "@mui/material";
import { createRef, useEffect, useState } from "react";

const BooleanEditor = ({ row, column, onRowChange, disabled = false }) => {
  let ref = createRef();

  const [checked, setChecked] = useState(
    row?.[column?.key] ? row[column.key] : false
  );

  useEffect(() => {
    ref.focus();
  }, [ref]);

  useEffect(() => {
    setChecked(row[column.key]);
  }, [column.key, row]);

  const handleChange = (event) => {
    onRowChange({ ...row, [column.key]: event.target.checked });
    setChecked(event.target.checked);
  };

  const handleChangeEnter = (event) => {
    const temp = checked;
    onRowChange({ ...row, [column.key]: !temp }, true);
    setChecked(!temp);
  };

  return (
    <Switch
      inputRef={(input) => {
        ref = input;
      }}
      onKeyDown={(e) => {
        if (e.code === "Enter" && e.target.value) {
          handleChangeEnter(e);
        }
      }}
      disableRipple
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default BooleanEditor;
