import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TabDataContainer from "../../containers/TabDataContainer";
import TabConfig from "../TabConfig";
import { StyledOngletwrapper } from "./styles";

const OngletWrapper = ({
  getTabData,
  updateTabSelected,
  tabSelected,
  tabSelectedConfig,
  tabsConfig,
  getOngletsConfig,
  yearSelected,
}) => {
  const { state: onglet = {} } = useLocation();
  const theme = useTheme();
  const [isConfigMode, setIsConfigMode] = useState(false);

  useEffect(() => {
    if (onglet?.id) {
      updateTabSelected(onglet.id);
    }
  }, [onglet, updateTabSelected]);

  useEffect(() => {
    if (!tabSelected) {
      return;
    }
    if (yearSelected && !tabSelectedConfig.isFrozenInTime) {
      getTabData(tabSelected, yearSelected);
    } else {
      getTabData(tabSelected, null);
    }
  }, [getTabData, tabSelected, yearSelected, tabSelectedConfig.isFrozenInTime]);

  const handleSwitchMode = () => {
    setIsConfigMode(!isConfigMode);
  };

  return (
    <StyledOngletwrapper theme={theme}>
      {isConfigMode ? (
        <TabConfig
          handleSwitchMode={handleSwitchMode}
          tabsConfig={tabsConfig}
          tabSelected={tabSelected}
          getOngletsConfig={getOngletsConfig}
        />
      ) : (
        <TabDataContainer handleSwitchMode={handleSwitchMode} />
      )}
    </StyledOngletwrapper>
  );
};

export default OngletWrapper;
