import { Chip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const FilterResume = ({
  filters = {},
  updateFilter,
  resetFilters,
}) => {
  const typeArray = ["date", "multi", "ok"];
  const handleDeleteFilter = (columnKey, type) => {
    updateFilter(columnKey, {
      type,
      value: typeArray.includes(type) ? [] : null,
    });
  };

  const renderChip = (filterKey) => {
    if (
      (filters[filterKey].type === "boolean" &&
        filters[filterKey].value !== null) ||
      filters[filterKey].value?.length > 0
    ) {
      return (
        <Chip
          label={`${filters[filterKey].columnName}: ${
            filters[filterKey].value.toString()?.trim()?.length === 0
              ? "(vide)"
              : filters[filterKey].value
          }`}
          onDelete={() => {
            handleDeleteFilter(filterKey, filters[filterKey].type);
          }}
        />
      );
    }
  };

  const renderReset = () => {
    return Object.keys(filters).map((filterKey) => {
      if (
        (filters[filterKey].type === "boolean" &&
          filters[filterKey].value !== null) ||
        filters[filterKey].value?.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    });
  };

  return (
    <div>
      {Object.keys(filters).map((filterKey) => {
        return <>{renderChip(filterKey)}</>;
      })}
      {renderReset().filter((filtre) => filtre).length > 0 && (
        <Chip
          label="Réinitialiser filtres"
          onClick={resetFilters}
          onDelete={resetFilters}
          deleteIcon={<DeleteIcon />}
          variant="outlined"
        />
      )}
    </div>
  );
};

export default FilterResume;
