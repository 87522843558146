import { connect } from "react-redux";

import { tabSelectors } from "../store/tab";

import AddUser from "../components/AddEditUserComponent/AddUser";
import { addUser } from "../services/user";
import { toastActions } from "../store/toast";
import { userActions } from "../store/user";

export default connect(
  (state) => ({
    tabsList: tabSelectors.tabsList(state),
  }),
  (dispatch) => ({
    addUser: (values) => {
      dispatch(userActions.startGettingUsers());
      return addUser(values)
        .then((data) => {
          dispatch(userActions.addUserToUserList(data));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
          throw new Error(err);
        });
    },
  }),
  (stateToPros, dispatchToProps, ownProps) => ({
    ...stateToPros,
    ...dispatchToProps,
    ...ownProps,
  })
)(AddUser);
