/* eslint-disable import/no-anonymous-default-export */
import types from "./types";

const updateClientList = (clientList) => ({
  type: types.UPDATE_CLIENT_LIST,
  payload: {
    clientList,
  },
});

const updateYearSelected = (year) => ({
  type: types.UPDATE_YEAR,
  payload: {
    year,
  },
});

const addClientsToClientList = (client) => ({
  type: types.ADD_CLIENT_TO_LIST,
  payload: {
    client,
  },
});
const startGettingClients = () => ({
  type: types.IS_LOADING_CLIENTS,
  payload: {},
});

export default { startGettingClients, updateClientList, updateYearSelected, addClientsToClientList };
