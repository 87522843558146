import { connect } from "react-redux";
import BooleanHeaderWithFilter from "../../components/TabHeaders/BooleanHeaderWithFilter";
import { tabActions, tabSelectors } from "../../store/tab";

export default connect(
  (state) => ({
    tabSelected: tabSelectors.tabSelected(state),
    filters: tabSelectors.filtersTabSelected(state),
  }),
  (dispatch) => ({
    updateFilter: (filterKey, value, tabSelected, columnName) => {
      dispatch(tabActions.updateFilter(filterKey, value, tabSelected, columnName));
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
    updateFilter: (filterKey, value, columnName = null) => {
      dispatchToProps.updateFilter(
        filterKey,
        value,
        stateToProps.tabSelected,
        columnName
      );
    },
  })
)(BooleanHeaderWithFilter);
