import { Autocomplete } from "@mui/material";
import styled from "styled-components";

export const StyledAutocomplete = styled(Autocomplete)`
  .MuiOutlinedInput-root,
  .MuiAutocomplete-hasPopupIcon,
  .MuiAutocomplete-inputRoot {
    padding: 0;
  }
`;

export const StyledOption = styled("span")`
  min-height: 25px !important;
  background-color: ${({ option }) => {
        return option.trim().length === 0 ? "#E8ECFF !important" : "";
    }};
`;
