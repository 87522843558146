import { IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { StyledTextField } from "./styles";
import { useLocation } from "react-router-dom";
import HeaderTitle from "./HeaderTitle";

const TextHeaderWithFilter = ({
  columnKey,
  columnName,
  columnSubName,
  filters,
  updateFilter,
  columnType,
}) => {
  const location = useLocation();
  const [filterValue, setFilterValue] = useState("");

  const handleChangeFilter = (e) => {
    updateFilter(
      columnKey,
      { type: columnType, value: e.target.value },
      location,
      columnName
    );
    setFilterValue(e.target.value);
  };

  const handleClearFilter = () => {
    updateFilter(columnKey, { type: columnType, value: "" }, location);
    setFilterValue("");
  };

  useEffect(() => {
    if (filters?.[columnKey]?.value) {
      setFilterValue(filters[columnKey].value);
    } else {
      setFilterValue("");
    }
  }, [columnKey, filters]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <HeaderTitle columnName={columnName} columnSubName={columnSubName} />
      <StyledTextField
        classes={{ root: "styledRoot" }}
        margin="dense"
        size="small"
        fullWidth
        type="text"
        onChange={handleChangeFilter}
        value={filterValue}
        InputProps={{
          endAdornment: filterValue && (
            <IconButton onClick={handleClearFilter}>
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
};

export default TextHeaderWithFilter;
