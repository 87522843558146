import React from "react";
import styled from "@emotion/styled";

export const StyledForm = styled("form")`
  margin: 1rem;
  & > div {
    margin: 0.5rem 0;
  }
`;

const Form = ({ children, onSubmit }) => (
  <StyledForm onSubmit={onSubmit}>{children}</StyledForm>
);

export default Form;
