import { connect } from "react-redux";

import { tabSelectors } from "../store/tab";

import AddClient from "../components/AddEditClient/AddClient";
import { addClient } from "../services/client";
import { clientActions } from "../store/client";
import { toastActions } from "../store/toast";

export default connect(
  (state) => ({
    tabsList: tabSelectors.tabsList(state),
  }),
  (dispatch) => ({
    addClient: (values) => {
      dispatch(clientActions.startGettingClients());
      return addClient(values)
        .then((data) => {
          dispatch(clientActions.addClientsToClientList(data));
          dispatch(toastActions.updateToast("Client ajouté avec succès"));
        })
        .catch((err) => {
          dispatch(
            toastActions.updateToast("Erreur lors de l'ajout client", "error")
          );
          throw new Error(err);
        });
    },
  }),

  (stateToPros, dispatchToProps, ownProps) => ({
    ...stateToPros,
    ...dispatchToProps,
    ...ownProps,
  })
)(AddClient);
