import { useFormik } from "formik";
import * as Yup from "yup";
import MyModal from "../Widgets/Modal";
import { Box } from "@mui/material";
import MyForm from "../Widgets/Form";
import InputTextField from "../Widgets/InputTextField";
import { useMutation } from "react-query";
import { editTab } from "../../services/tab";
import { useDispatch } from "react-redux";
import { tabActions } from "../../store/tab";

export const EditOngletModal = ({ isOpen, onClose, onglet }) => {
    const dispatch = useDispatch();
    const mutation = useMutation((name) => editTab({ id: onglet.id, name }), {
        onSuccess: (data) => {
            dispatch(tabActions.updateTabsConfig(data))
            formik.resetForm({
                values: {
                    name: "",
                }
            });
            onClose();
        },
        onError: () => { },
    });

    const formik = useFormik({
        initialValues: {
            name: onglet?.name ?? ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Nom requis"),
        }),
        onSubmit: async () => {
            mutation.mutate(formik.values.name);
        },
    });

    return (
        <MyModal
            open={isOpen}
            displayButtons
            handleCancel={onClose}
            handleValidate={() => {
                formik.handleSubmit();
            }}
        >
            <Box minWidth="300px">
                <h2>Modifier un onglet</h2>
                <MyForm onSubmit={formik.handleSubmit}>
                    <InputTextField formik={formik} formikItemKey="name" label="Nom" />
                </MyForm>
            </Box>
        </MyModal>
    );
}