import { TextField } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { StyledAutocomplete, StyledOption } from "./styles";

const MultiEditor = ({ options, row, column, onRowChange }) => {
  const [multiValue, setMultiValue] = useState("");
  let ref = createRef();
  const [highLightValue, setHightLightValue] = useState(null);

  useEffect(() => {
    setMultiValue(row[column.key]);
  }, [column.key, row]);

  useEffect(() => {
    ref.focus();
  }, [ref]);

  const handleChangeTextInputEnter = (value, e) => {
    setMultiValue(value);
    onRowChange({ ...row, [column.key]: value }, true);
    e.stopPropagation();
  };

  const handleChangeOnClick = (value) => {
    setMultiValue(value);
    onRowChange({ ...row, [column.key]: value }, true);
  };

  return (
    <StyledAutocomplete
      forcePopupIcon={false}
      classes={{ root: "styledRoot" }}
      key={column.key}
      id={column.key}
      autoFocus
      options={options}
      value={multiValue}
      disableClearable
      onHighlightChange={(e, value) => {
        setHightLightValue(value);
      }}
      openOnFocus
      onChange={(e, values) => {
        handleChangeOnClick(values, column.key);
      }}
      renderInput={(params) => {
        return (
          <TextField
            inputRef={(input) => {
              ref = input;
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (highLightValue !== null) {
                  e.stopPropagation();
                  handleChangeTextInputEnter(highLightValue, e);
                }
              }
            }}
            {...params}
            fullWidth
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <StyledOption {...props} option={option}>{`${option}`}</StyledOption>
        );
      }}
    />
  );
};

export default MultiEditor;
