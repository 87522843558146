import styled from "@emotion/styled";

export const StyledDiv = styled("div")`
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  height: fit-content;
`;

