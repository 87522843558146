import { Avatar, Chip, TextField, Tooltip } from "@mui/material";
import { useState } from "react";
import { StyledAutocomplete } from "./styles";
import { useSelector } from "react-redux";

const PersonAction = ({
    onChange
}) => {
    const users = useSelector((state) => state.user.userList);
    const [values, setValues] = useState([]);

    const handleChange = (values) => {
        setValues(values);
        onChange(values)
    };

    return (
        <div>
            <StyledAutocomplete
                forcePopupIcon={false}
                classes={{ root: "styledRoot" }}
                multiple
                filterSelectedOptions
                autoFocus
                options={
                    users
                }
                value={values}
                isOptionEqualToValue={(option, val) => option._id === val._id}
                disableClearable
                renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => {
                        const fullName = `${option.firstName} ${option.lastName}`;
                        return (
                            <Chip
                                {...getTagProps({ index })}
                                avatar={
                                    <Tooltip title={fullName}>
                                        <Avatar
                                            sx={{ bgcolor: option.color, width: 32, height: 32 }}
                                        >
                                            <span style={{ fontSize: "large" }}>
                                                {option?.username?.toUpperCase()}
                                            </span>
                                        </Avatar>
                                    </Tooltip>
                                }
                            />
                        );
                    });
                }}
                getOptionLabel={(option) => option.username}
                onChange={(e, values) => {
                    handleChange(values)
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            fullWidth
                        />
                    );
                }}
            />
        </div>
    );
};

export default PersonAction;
