import styled from "styled-components";

export const StyledWrapperDatePicker = styled("div")`
  width: auto;
  .react-datepicker__navigation,
  .react-datepicker__header {
    display: none;
  }
  .react-datepicker__year {
    width: 6rem;
  }
  .react-datepicker__year-text {
    width: 6rem;
    font-size: 0.9rem;
  }
  .react-datepicker__year-text--selected {
    background-color: ${({ theme }) => theme.palette.internalPrimary.main};
  }
`;
