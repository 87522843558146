import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useResetPassword } from "../../queries/auth";
import InputTextField from "../Widgets/InputTextField";
import { Alert, Box } from "@mui/material";
import Card from "../Widgets/Card";
import ButtonComponent from "../Widgets/Button";
import { StyledResetPassword } from "./styles";
import MyForm from "../Widgets/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { LOGIN } from "../../routes/utils";

const ResetPassword = () => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Mot de passe requis"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Les mots de passe ne correspondent pas')
                .required('Confirmation du mot de passe requis'),
        }),
        onSubmit: () => {
            setIsFormValid(true);
        },
    });

    const { error } = useResetPassword({
        ...formik.values,
        token: location.search.split("=")[1]
    }, {
        enabled: isFormValid,
        onSuccess: (data) => {
            setIsFormValid(false);
            setSuccessMessage(data.message);
        },
    });

    useEffect(() => {
        setIsFormValid(false);
    }, [error]);

    return (
        <StyledResetPassword>
            <Card>
                <Box padding='20px'>
                    <h2>Réinitialiser le mot de passe</h2>
                    <p>
                        Saisir votre nouveau mot de passe
                    </p>
                    <MyForm onSubmit={formik.handleSubmit}>
                        <InputTextField
                            type="password"
                            formik={formik}
                            formikItemKey="password"
                            label="Mot de passe"
                        />
                        <InputTextField
                            type="password"
                            formik={formik}
                            formikItemKey="confirmPassword"
                            label="Confirmation du mot de passe"
                        />
                        <div>
                            <ButtonComponent submit title="Valider" />
                        </div>
                        {error && (
                            <Alert style={{ marginTop: "1rem" }} severity="error">
                                {error.message}
                            </Alert>
                        )}
                        {successMessage && (
                            <>
                                <Alert style={{ marginTop: "1rem" }} severity="success">
                                    {successMessage}
                                </Alert>
                                <ButtonComponent
                                    style={{ marginTop: "1rem" }}
                                    variant="text"
                                    title="Se connecter"
                                    onClickMethod={() => {
                                        navigate(LOGIN)
                                    }}
                                />
                            </>
                        )}
                    </MyForm>
                </Box>

            </Card>
        </StyledResetPassword>
    );
};

export default ResetPassword;
