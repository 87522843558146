import { connect } from "react-redux";

import { tabSelectors, tabActions } from "../store/tab";
import { clientActions } from "../store/client";
import { toastActions } from "../store/toast";
import { userActions } from "../store/user";
import GlobalWrapper from "../components/GlobalWrapper";
import { getOnglets } from "../services/system";
import { getClientList } from "../services/client";
import { getUsers } from "../services/user";

export default connect(
  (state) => ({
    isLoadingOnglets: tabSelectors.tabsLoading(state),
    tabsList: tabSelectors.tabsList(state),
  }),
  (dispatch) => ({
    getOngletsConfig: () => {
      dispatch(tabActions.startGettingTabs());
      getOnglets()
        .then((data) => {
          dispatch(tabActions.updateTabsConfig(data));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    getClientList: () => {
      dispatch(clientActions.startGettingClients());
      getClientList()
        .then((data) => {
          dispatch(clientActions.updateClientList(data));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    getUserList: () => {
      dispatch(userActions.startGettingUsers());
      getUsers()
        .then((data) => {
          dispatch(userActions.updateUserList(data));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
    },
    updateRouteToStore: (location) => {
      dispatch(tabActions.updateRouteToStore(location));
    },
  })
)(GlobalWrapper);
