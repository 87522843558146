import { useState } from "react";
import { useEffect } from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import Button from "./Button";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { format } from "date-fns";

export const StyledCSVLink = styled(CSVLink)`
  color: inherit;
  text-decoration: none;
`;

const CSVExport = ({
  columns = [],
  rows = [],
  fileName = "default_export",
}) => {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);

  const chooseRightFormat = (type, data, col) => {
    switch (col?.type) {
      case "person": {
        return data
          .map((person) => person.firstName + " " + person.lastName)
          .join();
      }
      case "date": {
        return format(new Date(data), "dd-MM");
      }
      default:
        return data;
    }
  };

  const formatData = (rows, columns) => {
    return rows.map((row) => {
      let tempRow = { ...row };
      Object.keys(row).forEach((i) => {
        return (tempRow[i] = row[i]
          ? chooseRightFormat(
              i,
              row[i],
              columns.filter((col) => col.key === i)[0]
            )
          : "");
      });
      return tempRow;
    });
  };

  useEffect(() => {
    setHeaders(
      columns.map((i) => ({
        key: i.key,
        label: `${i.name}${i.subName ? ` - ${i.subName}` : ``}`,
      }))
    );
    setData(formatData(rows, columns));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rows]);

  return (
    <Button
      endIcon={<FileDownloadIcon />}
      title={
        <StyledCSVLink
          data={data}
          headers={headers}
          filename={fileName + ".csv"}
          target="_blank"
          asyncOnClick={true}
        >
          Télécharger
        </StyledCSVLink>
      }
    ></Button>
  );
};

export default CSVExport;
