import { connect } from "react-redux";

import { userSelectors } from "../../store/user";

import RoleChangeEditor from "../../components/TabEditor/RoleChangeEditor";

export default connect(
  (state) => ({
    users: userSelectors.userList(state),
  }),
  (dispatch) => ({}),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
  })
)(RoleChangeEditor);
