export const REGISTER_ERROR = {
  401: "Vous n'êtes pas autorisés",
  400: "Un champ obligatoire est manquant",
  409: "Pseudo déjà utilisé",
};

export const LOGIN_ERROR = {
  401: "Pseudo ou mot de passe invalides",
};

export const UNKNOWN_ERROR = "Erreur serveur interne";

export const ADD_CLIENT_ERROR = {
  401: "Vous n'êtes pas autorisés",
  400: "Un champ obligatoire est manquant",
  409: "Nom de société ou SIREN déjà enregistré",
};
