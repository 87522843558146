import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  LOGIN,
  TAB,
  CONFIG_ONGLETS,
  CONFIG_USERS,
  CONFIG_CLIENTS,
  HOME,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from "./utils";
import Login from "../components/Login";

import PrivateOutlet from "./PrivateRoute";
import OngletWrapperContainer from "../containers/OngletWrapperContainer";
import GestionUtilisateursContainer from "../containers/GestionUtilisateursContainer";
import GestionClientsContainer from "../containers/GestionClientsContainer";
import DashboardContainer from "../containers/DashboardContainer";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import GestionOngletsComponent from "../components/GestionOnglets";

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={"/"} element={<PrivateOutlet />}>
        <Route path={HOME} element={<DashboardContainer />} />
        <Route path={""} element={<DashboardContainer />} />
        <Route path={CONFIG_ONGLETS} element={<GestionOngletsComponent />} />
        <Route path={CONFIG_USERS} element={<GestionUtilisateursContainer />} />
        <Route path={CONFIG_CLIENTS} element={<GestionClientsContainer />} />
        <Route path={TAB} element={<OngletWrapperContainer />} />
      </Route>
    </Routes>
  );
};

export default RoutesComponent;
