/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import EditClientComponent from "../AddEditClient/EditClient";
import { StyledGlobalDivTabData } from "../TabData/styles";
import DataGrid, { SelectColumn } from "react-data-grid";
import ButtonComponent from "../Widgets/Button";
import { StyledRow, StyledRowWithExpirationYear } from "./styles";
import { Box, FormControlLabel, Switch, useTheme } from "@mui/material";
import AddClientContainer from "../../containers/AddClientContainer";
import { checkRightFilter } from "../TabHeaders/utils";
import ClientTotal from "../ClientTotal";
import EditClientsBulkModal from "../EditClientsBulkModal";

const GestionClientsComponent = ({
  isLoadingClients,
  clientsRows,
  clientsColumns,
  updateClients,
  updateClient,
  updateManyClientsBulk,
  tabsList,
  filters,
  handleCopyPasteDifferentType,
}) => {
  const [isAddingClient, setIsAddingClientStatus] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState(() => new Set());
  const [sortColumns, setSortColumns] = useState([]);
  const [clientUpdatedRows, setClientUpdatedRows] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isShowingExpiredClient, setIsShowingExpiredClient] = useState(false);
  const [isEditClientsBulkModalOpen, setIsEditClientsBulkModalOpen] = useState(false);

  const [roleAssocationEditStatus, setRoleAssocationEditStatus] =
    useState(false);

  useEffect(() => {
    if (Object.keys(clientsColumns).length > 0) {
      const clientColumns = Object.keys(clientsColumns).map((columnKey) => {
        return {
          ...clientsColumns[columnKey],
          editable: roleAssocationEditStatus,
          minWidth: columnKey.length * 10,
        }
      })
      setColumns(
        [SelectColumn, ...clientColumns]
      );
    }
  }, [roleAssocationEditStatus, setColumns, clientsColumns]);

  const filteredRows = useMemo(() => {
    if (filters) {
      return rows.filter((r) => {
        return Object.keys(filters)
          .map((filterKey) => {
            return checkRightFilter(
              filters[filterKey].type,
              r[filterKey],
              filters,
              filterKey
            );
          })
          .every((i) => i);
      });
    } else {
      return rows;
    }
  }, [rows, filters]);

  useEffect(() => {
    setRows(
      isShowingExpiredClient
        ? clientsRows
        : clientsRows.filter((row) => row.expiration_year == null)
    );
  }, [clientsRows, setRows, isShowingExpiredClient]);

  const checkOngletsPresence = (tabId, client) => {
    const onglets = client["onglets"];
    if (client[tabId]) {
      if (onglets.includes(tabId)) {
        return onglets;
      } else {
        return [...onglets, tabId];
      }
    } else {
      if (onglets.includes(tabId)) {
        return onglets.filter((tabIdPresent) => tabIdPresent !== tabId);
      } else {
        return onglets;
      }
    }
  };

  const handleEditClientClick = () => {
    if (roleAssocationEditStatus) {
      let finalUpdates = {};
      Object.keys(clientUpdatedRows).forEach((clientId) => {
        let tempClient = clientUpdatedRows[clientId];
        tabsList.forEach((tab) => {
          tempClient = {
            ...tempClient,
            onglets: checkOngletsPresence(tab.id, tempClient),
          };
          //delete tempClient[tab.id];
        });
        finalUpdates = {
          ...finalUpdates,
          [clientId]: {
            ...tempClient,
          },
        };
      });
      updateClients(finalUpdates).then(() => {
        setClientUpdatedRows({});
      });
    }
    setRoleAssocationEditStatus(!roleAssocationEditStatus);
  };

  const handleCancelEdit = () => {
    setRows(clientsRows);
    setRoleAssocationEditStatus(false);
  };

  const handleRowsChange = (rows, data) => {
    if (!roleAssocationEditStatus) {
      setRoleAssocationEditStatus(true);
    }
    setRows(rows);
    setClientUpdatedRows({
      ...clientUpdatedRows,
      [rows[data.indexes[0]]._id]: rows[data.indexes[0]],
    });
  };

  const handleRowClick = (row, column) => {
    if (column.key === 'select-row') {
      return;
    }
    if (!roleAssocationEditStatus) {
      let tempValues = row;
      Object.keys(row).forEach((key) => {
        if (key === key.toUpperCase()) {
          tempValues = {
            ...tempValues,
            [key]: row[key],
          };
        }
      });
      setSelectedClient(tempValues);
      setIsModalOpen(true);
    }
  };

  const handleCancelClientEdition = () => {
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  const handleValidateClientEdition = (values) => {
    let tempClient = values;
    tabsList.forEach((tab) => {
      tempClient = {
        ...tempClient,
      };
      delete tempClient[tab.id];
    });
    updateClient(tempClient);
    setIsModalOpen(false);
    setSelectedClient(null);
  };

  const handleChangeShowExpiredClient = (e) => {
    setIsShowingExpiredClient(e.target.checked);
  };

  const rowClass = (row) => {
    return row.expiration_year ? StyledRowWithExpirationYear : StyledRow;
  };

  const handlePaste = ({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) => {
    if (
      clientsColumns[sourceColumnKey].type !==
      clientsColumns[targetColumnKey].type
    ) {
      handleCopyPasteDifferentType();
      return targetRow;
    } else {
      return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
    }
  };

  const handleEditManyClients = (values) => {
    const { selectedRoles, selectedOnglets } = values;
    const clientsIds = Array.from(selectedRows);
    updateManyClientsBulk(clientsIds, selectedOnglets, selectedRoles).then(() => {
      setClientUpdatedRows({});
      setSelectedRows(new Set());
    });
  }

  const theme = useTheme();

  return (
    <StyledGlobalDivTabData theme={theme}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "inherit",
        }}
      >
        <h1>Liste des dossiers client</h1>
        <Box display='flex' gap='10px' alignItems='center'>
          <ButtonComponent
            disabled={isAddingClient}
            title={
              roleAssocationEditStatus ? "Sauvegarder" : "Modification rapide"
            }
            onClickMethod={handleEditClientClick}
          />
          {roleAssocationEditStatus && (
            <ButtonComponent
              title={"Annuler"}
              onClickMethod={handleCancelEdit}
              disabled={isAddingClient}
            />
          )}
          <FormControlLabel
            control={<Switch checked={isShowingExpiredClient} />}
            label="Afficher dossiers expirés"
            onChange={handleChangeShowExpiredClient}
            labelPlacement="start"
            style={{
              marginTop: '1em'
            }}
          />
          {selectedRows.size > 0 && (
            <ButtonComponent
              title={`Modifier ${selectedRows.size} lignes sélectionnées`}
              onClickMethod={() => setIsEditClientsBulkModalOpen(true)}
            />
          )}
          <ButtonComponent
            variant="contained"
            title={"Ajouter client"}
            disabled={isAddingClient || roleAssocationEditStatus}
            onClickMethod={() => {
              setIsAddingClientStatus(true);
            }}
          />
        </Box>

      </div>
      <div style={{ width: "80vw", height: "inherit", marginTop: "2vh" }}>
        <DataGrid
          className="rdg-light"
          onPaste={handlePaste}
          style={{ height: "90%" }}
          headerRowHeight={100}
          rowClass={rowClass}
          columns={columns}
          rows={filteredRows}
          onRowsChange={handleRowsChange}
          sortColumns={sortColumns}
          onSortColumnsChange={setSortColumns}
          onRowClick={handleRowClick}
          rowKeyGetter={(row) => row._id}
          selectedRows={selectedRows}
          onSelectedRowsChange={setSelectedRows}
          defaultColumnOptions={{
            sortable: true,
            resizable: true,
            filterable: true,
          }}
        />
        <ClientTotal clientsNumber={filteredRows.length} />
      </div>
      {selectedClient && (
        <EditClientComponent
          tabsList={tabsList}
          client={selectedClient}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancelClientEdition={handleCancelClientEdition}
          handleValidateClientEdition={handleValidateClientEdition}
        />
      )}
      {isAddingClient && (
        <AddClientContainer
          isAddingClient={isAddingClient}
          setIsAddingClientStatus={setIsAddingClientStatus}
        />
      )}
      <EditClientsBulkModal
        tabsList={tabsList}
        isModalOpen={isEditClientsBulkModalOpen}
        rowsCount={selectedRows.size}
        onClose={() => setIsEditClientsBulkModalOpen(false)}
        onSubmit={(values) => {
          handleEditManyClients(values);
        }}
      />
    </StyledGlobalDivTabData>
  );
};

export default GestionClientsComponent;
