import { useTheme } from "@mui/material";
import { StyledTextCell } from "./style";

const TextFormatter = ({ column, row }) => {
  const theme = useTheme();
  return (
    <StyledTextCell theme={theme} content={row[column.key]}>
      {row[column.key]}
    </StyledTextCell>
  );
};
export default TextFormatter;
