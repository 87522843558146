/* eslint-disable no-unused-vars */
import { axiosInstance } from "../App";

export const addUser = (params) => {
  return axiosInstance
    .post("users", params)
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const deleteUser = (userId) => {
  return axiosInstance
    .delete("users/" + userId)
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateUser = (params) => {
  return axiosInstance
    .put("users", params)
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const getRoles = () => {
  return axiosInstance.get("users/roles", {}).then((response) => response.data);
};

export const getMyProfile = () => {
  return axiosInstance.get("users/me", {});
};

export const getUsers = () => {
  return axiosInstance.get("users", {}).then((response) => {
    const users = response.data;
    users.push({ firstName: "N/A", lastName: "", username: "N/A" });
    return users;
  });
};
