import { useQuery } from "react-query";
import {
  getRoles,
  getMyProfile,
  getUsers,
} from "../../services/user";

export const useRoles = (options) => {
  return useQuery(["roles"], getRoles, { ...options });
};

export const useMe = (options) => {
  return useQuery(["me"], getMyProfile, {
    retry: false,
    ...options,
  });
};

export const useUsers = () => {
  return useQuery(["users"], getUsers);
};
