/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import MyForm from "../Widgets/Form";
import { useRoles, useUsers } from "../../queries/user";
import InputTextField from "../Widgets/InputTextField";
import { StyledDiv } from "./styles";
import DatePicker from "@mui/lab/DatePicker";

import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";
import { useEffect } from "react";

const AddEditClientComponent = ({
  tabsList,
  formik,
  isEditMode = false,
  children,
  groupesOptions = [],
}) => {
  const { data: roles = [], error } = useRoles();

  const { data: users = [] } = useUsers();

  const [selectedOnglets, setSelectedOnglets] = useState(
    formik.values.onglets ?? []
  );
  const [groupeInputValue, setGroupeInputValue] = useState(
    formik.values.groupe_attached ?? ""
  );

  useEffect(() => {
    formik.setFieldValue("groupe_attached", groupeInputValue);
  }, [groupeInputValue]);

  useEffect(() => {
    formik.setFieldValue("onglets", selectedOnglets);
  }, [selectedOnglets]);

  const handleChangeMultiple = async (event, values, roleKey) => {
    await formik.setFieldValue(roleKey, values);
  };

  return (
    <StyledDiv>
      {!isEditMode && <h1>Ajouter un client</h1>}
      <MyForm>
        <InputTextField
          formik={formik}
          formikItemKey="siren"
          label="SIREN"
          type="number"
        />
        <InputTextField
          formik={formik}
          formikItemKey="name"
          label="Nom de la société"
        />
        <Autocomplete
          disablePortal
          id="groupe_attached"
          options={groupesOptions}
          onInputChange={(_, value) => setGroupeInputValue(value)}
          value={groupeInputValue}
          renderInput={(params) => (
            <TextField {...params} label="Groupe" fullWidth />
          )}
        />
        <DatePicker
          inputFormat="dd/MM"
          label="Date de cloture de Bilan"
          value={formik.values["date_cloture_bilan"]}
          onChange={(newValue) => {
            formik.setFieldValue("date_cloture_bilan", newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DatePicker
          views={["year"]}
          label="Date de début de collaboration"
          value={new Date(formik.values["date_debut_collaboration"], 1, 1)}
          onChange={(newValue) => {
            formik.setFieldValue(
              "date_debut_collaboration",
              newValue.getFullYear()
            );
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        {roles.map((role) => {
          return (
            <Autocomplete
              value={formik.values[role.id] ?? []}
              key={role.id}
              multiple
              id={role.id}
              filterSelectedOptions
              options={users.filter((user) => user.role === role.id)}
              isOptionEqualToValue={(option, val) => option._id === val._id}
              getOptionLabel={(option) => `${option.username}`}
              onChange={(e, values) => {
                handleChangeMultiple(e, values, role.id);
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    label={role.name}
                    placeholder="Sacha"
                  />
                );
              }}
            />
          );
        })}
        <FormControl error={error}>
          <FormLabel>Présents dans les onglets: </FormLabel>
          <FormGroup>
            {tabsList.map((onglet) => (
              <FormControlLabel
                key={onglet.id}
                control={
                  <Checkbox
                    checked={selectedOnglets.includes(onglet.id)}
                    onChange={() => {
                      if (selectedOnglets.includes(onglet.id)) {
                        setSelectedOnglets(
                          selectedOnglets.filter((value) => {
                            return value !== onglet.id;
                          })
                        );
                      } else {
                        setSelectedOnglets(selectedOnglets.concat([onglet.id]));
                      }
                    }}
                    name={onglet.name}
                  />
                }
                label={onglet.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </MyForm>
      {children}
    </StyledDiv>
  );
};

export default AddEditClientComponent;
