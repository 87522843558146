import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddEditUserComponent from ".";
import AlertComponent from "../Widgets/Alert";
import { useRoles } from "../../queries/user";
import { Alert, AlertTitle } from "@mui/material";
import MyModal from "../Widgets/Modal";
import ButtonComponent from "../Widgets/Button";

const AddUser = ({ isAddingUser, setIsAddingUserStatus, addUser }) => {
  const { data: roles = [], error } = useRoles();

  const formik = useFormik({
    initialValues: {
      username: "",
      password: `virtuoz${Math.floor(Math.random() * 100)}`,
      email: "",
      lastName: "",
      firstName: "",
      role: "",
    },
    validationSchema: Yup.object({
      lastName: Yup.string().required("Nom requis"),
      firstName: Yup.string().required("Prénom requis"),
      username: Yup.string().required("Pseudo requis"),
      password: Yup.string().required("Mot de passe requis"),
      role: Yup.string().required("Role requis"),
    }),
    onSubmit: (values) => {
      addUser(values)
        .then(() => {
          setUserRegistered({ ...formik.values });
          formik.resetForm();
        })
        .catch(() => {});
    },
  });

  const [userRegistered, setUserRegistered] = useState(null);

  return (
    <MyModal
      open={isAddingUser}
      displayButtons={!userRegistered}
      handleCancel={() => {
        setIsAddingUserStatus(false);
      }}
      handleValidate={() => {
        formik.handleSubmit();
      }}
    >
      <AddEditUserComponent roles={roles} formik={formik}>
        {error && <AlertComponent severity="error" msg={error?.message} />}
        {userRegistered && (
          <>
            <Alert severity="success">
              <AlertTitle>
                Félicitations - Merci d'envoyer les identifiants à{" "}
                {userRegistered.firstName} {userRegistered.lastName}
              </AlertTitle>
              Voici son Pseudo <strong>{userRegistered.username}</strong>
              <br />
              Voici son Mot de passe <strong>{userRegistered.password}</strong>
            </Alert>
            <ButtonComponent
              title="Fermer"
              variant="contained"
              onClickMethod={() => {
                setUserRegistered(null);
                setIsAddingUserStatus(false);
              }}
            />
          </>
        )}
      </AddEditUserComponent>
    </MyModal>
  );
};

export default AddUser;
