import React from "react";
import {
  StyledButtonMenuDeroulant,
  StyledMenuItem,
  StyledButton,
  StyledRegularButton,
} from "./styles";
import { Box, Menu, useTheme } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  TAB,
  CONFIG_ONGLETS,
  CONFIG_USERS,
  CONFIG_CLIENTS,
  HOME,
} from "../../routes/utils";

const MENU_LIST = [
  {
    title: "Gestion des onglets",
    link: CONFIG_ONGLETS,
  },
  {
    title: "Gestion des utilisateurs",
    link: CONFIG_USERS,
  },
  {
    title: "Gestion des dossiers clients",
    link: CONFIG_CLIENTS,
  },
];

const MenuAppBar = ({ userContext, tabsList = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (route) => {
    navigate(route);
    setAnchorEl(null);
  };

  const handleClickOnglet = (onglet) => {
    navigate(TAB, { state: onglet });
  };

  const theme = useTheme();

  return (
    <Box
      display="flex"
      borderRadius="1em"
      bgcolor={theme.palette.internalPrimary.main}
      maxWidth='80%'
      overflow='auto'
    >
      <Box sx={{ flexGrow: 1, display: { md: "flex" } }}>
        <Box
          bgcolor={location.pathname === HOME && theme.palette.internalPrimary.contrastText}
          borderRadius='1em'
          borderColor={theme.palette.internalPrimary.main}
          border='1px solid'
        >
          <StyledRegularButton
            theme={theme}
            location={location}
            route={HOME}
            onClick={() => {
              navigate(HOME);
            }}
          >
            DASHBOARD
          </StyledRegularButton>
        </Box>
        {tabsList.map((onglet) => {
          return (
            <Box
              bgcolor={location?.state?.id === onglet.id &&
                theme.palette.internalPrimary.contrastText}
              borderRadius='1em'
              borderColor={theme.palette.internalPrimary.main}
              border='1px solid'
            >
              {onglet.visibility ? (
                <StyledButton
                  theme={theme}
                  location={location}
                  onglet={onglet}
                  key={onglet.id}
                  onClick={() => {
                    handleClickOnglet(onglet);
                  }}
                >
                  {onglet.name}
                </StyledButton>
              ) : (
                <>
                  {userContext?.profile?.isAdmin && (
                    <StyledButton
                      theme={theme}
                      location={location}
                      onglet={onglet}
                      key={onglet.id}
                      onClick={() => {
                        handleClickOnglet(onglet);
                      }}
                    >
                      {onglet.name}
                    </StyledButton>
                  )}
                </>
              )}
            </Box>
          );
        })}
      </Box>
      {userContext?.profile?.isAdmin && (
        <>
          <StyledButtonMenuDeroulant
            theme={theme}
            location={location}
            id="menu-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            Admin
          </StyledButtonMenuDeroulant>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "menu-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {MENU_LIST.map((item) => (
              <StyledMenuItem
                theme={theme}
                link={item.link}
                location={location}
                key={item.title}
                onClick={() => handleClose(item.link)}
              >
                {item.title}
              </StyledMenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};

export default MenuAppBar;
