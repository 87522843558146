import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

const PersonPicker = ({
  userList = [],
  updateCollabSelected,
  collabSelected,
}) => {
  const [collabSelectedTemp, setCollabSelected] = useState({});

  useEffect(() => {
    if (collabSelected) {
      setCollabSelected(collabSelected);
    }
  }, [collabSelected]);

  const handleChangeCollab = (event) => {
    const collab = event.target.value;
    setCollabSelected(collab);
    updateCollabSelected(collab);
  };

  return (
    <FormControl style={{ width: "20%" }}>
      <InputLabel id="collab">Collaborateur</InputLabel>
      <Select
        renderValue={(option) => option?.username}
        labelId="select-collab"
        id="select-collabt"
        value={collabSelectedTemp}
        label="Collaborateur"
        onChange={handleChangeCollab}
      >
        {userList.map((user, i) => (
          <MenuItem value={user} key={i}>
            {user.username}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PersonPicker;
