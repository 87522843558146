import MyModal from "./Widgets/Modal";

const DeleteUserModal = ({
  user,
  isModalDeleteUser,
  setIsModalDeleteUser,
  deleteUser,
}) => (
  <MyModal
    title={`Suppresion de l'utilisateur ${user.firstName} ${user.lastName}`}
    open={isModalDeleteUser}
    handleCancel={() => {
      setIsModalDeleteUser(false);
    }}
    handleValidate={() => {
      deleteUser(user);
      setIsModalDeleteUser(false);
    }}
  >
    Êtes-vous sûr de vouloir supprimer cet utilisateur ?
  </MyModal>
);

export default DeleteUserModal;
