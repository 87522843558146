/* eslint-disable import/no-anonymous-default-export */
import types from "./types";

const startGettingDashboard = () => ({
  type: types.IS_LOADING_DASHBOARD,
  payload: {},
});

const updateDashboardData = (data) => ({
  type: types.UPDATE_DASHBOARD_DATA,
  payload: {
    data,
  },
});

export default { startGettingDashboard, updateDashboardData };
