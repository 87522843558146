import { connect } from "react-redux";

import { tabActions, tabSelectors } from "../store/tab";

import YearPicker from "../components/YearPicker";
import { clientActions, clientSelectors } from "../store/client";

export default connect(
  (state) => ({
    tabSelected: tabSelectors.tabSelected(state),
    yearSelected: clientSelectors.yearSelected(state),
  }),
  (dispatch) => ({
    resetFilters: (tabSelected) => {
      dispatch(tabActions.resetFilterByTab(tabSelected));
    },
    updateYearPicked: (newYear) => {
      dispatch(clientActions.updateYearSelected(newYear));
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
    updateYearPicked: (newYear) => {
      dispatchToProps.resetFilters(stateToProps.tabSelected);
      dispatchToProps.updateYearPicked(newYear);
    },
  })
)(YearPicker);
