import { StyledTextFieldList, StyledConfigDiv } from "./styles";
import EditIcon from "@mui/icons-material/Edit";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import { useTheme } from "@mui/material";

const SortableItem = SortableElement(
  ({
    value,
    internalIndex,
    isAddingColumn,
    isEditingColumn,
    editColonneByIndex,
  }) => {
    const theme = useTheme();
    return (
      <StyledConfigDiv theme={theme}>
        <span style={{ wordWrap: "anywhere" }}>{value.name}</span>
        <span style={{ wordWrap: "anywhere" }}>{value.subName}</span>
        {!isAddingColumn && !isEditingColumn && (
          <EditIcon
            onClick={() => {
              editColonneByIndex(internalIndex);
            }}
          />
        )}
      </StyledConfigDiv>
    );
  }
);

const SortableList = SortableContainer(
  ({ columns, isAddingColumn, isEditingColumn, editColonneByIndex }) => {
    return (
      <ul style={{ display: "flex", overflowX: "scroll", padding: 0 }}>
        {columns.map((col, index) => {
          return (
            <SortableItem
              key={`col-${index}`}
              index={index}
              internalIndex={index}
              value={col}
              isAddingColumn={isAddingColumn}
              isEditingColumn={isEditingColumn}
              editColonneByIndex={editColonneByIndex}
            />
          );
        })}
      </ul>
    );
  }
);

const ColumnList = ({
  handleChangePosition,
  myColumns,
  isAddingColumn,
  isEditingColumn,
  editColonneByIndex,
}) => {
  const handleChange = ({ oldIndex, newIndex }) => {
    const temp = arrayMove(myColumns, oldIndex, newIndex);
    handleChangePosition(temp);
  };
  return (
    <StyledTextFieldList>
      Colonnes:
      <SortableList
        distance={2}
        lockAxis="x"
        axis="x"
        isAddingColumn={isAddingColumn}
        isEditingColumn={isEditingColumn}
        editColonneByIndex={editColonneByIndex}
        columns={myColumns}
        onSortEnd={handleChange}
      />
    </StyledTextFieldList>
  );
};

export default ColumnList;
