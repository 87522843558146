import { axiosInstance } from "../App";

export const getTabData = async (ongletID, year) => {
  return axiosInstance
    .get("tab", { params: { onglet: ongletID, year } })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateColumnsTabs = (columns, tabID) => {
  return axiosInstance
    .post("tab/column", { columns: columns, tabID: tabID })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateTabConfig = ({ visibility, dashboardVisibility, isFrozenInTime, tabID }) => {
  return axiosInstance
    .post("tab/config", {
      visibility,
      dashboardVisibility,
      isFrozenInTime,
      tabID,
    })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const updateLinkedStatus = (linked, tabID, clientsColumnsVisibled) => {
  return axiosInstance
    .post("tab/linked", {
      linked: linked,
      tabID: tabID,
      clientsColumnsVisibled,
    })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const addTab = ({ name }) => {
  return axiosInstance
    .post("tab", { onglet: name })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};

export const editTab = ({ id, name }) => {
  return axiosInstance
    .put("tab", { id, name })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};


export const deleteTab = ({ id }) => {
  return axiosInstance
    .delete(`tab/${id}`)
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};


export const updateTabContent = (columns, tabID, year) => {
  return axiosInstance
    .post("tab/content", { tabID, columns, year })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};


export const updateColumnsDataBulk = (columns, values, tabID, year) => {
  return axiosInstance
    .post("tab/update-columns-data-bulk", { tabID, columns, values, year })
    .then(({ data }) => data)
    .catch((err) => {
      throw new Error(err.response.data.message);
    });
};
