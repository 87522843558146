import { TextField } from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { StyledAutocomplete } from "./styles";

const TextInputEditor = ({
  row,
  column,
  onRowChange,
  onClose,
  scrollToCell,
}) => {
  let ref = createRef();
  const [value, setValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [highLightValue, setHightLightValue] = useState(null);

  useEffect(() => {
    if (row[column.key]) {
      setValue(row[column.key]);
      setInputValue(row[column.key]);
    }
  }, [column.key, row]);

  const handleChangeTextInput = (value, e) => {
    setInputValue(value);
    onRowChange({ ...row, [column.key]: value });
  };

  const handleChangeTextInputEnter = (value, e) => {
    setValue(value);
    onRowChange({ ...row, [column.key]: value }, true);
    e.stopPropagation();
  };

  useEffect(() => {
    ref.focus();
  }, [ref]);

  return (
    <StyledAutocomplete
      ref={ref}
      freeSolo
      value={value}
      key={column.key}
      id={column.key}
      options={["N/A"]}
      onHighlightChange={(e, value) => {
        setHightLightValue(value);
      }}
      onInputChange={(event, newInputValue) => {
        handleChangeTextInput(newInputValue);
      }}
      inputValue={inputValue}
      renderInput={(params) => {
        return (
          <TextField
            inputRef={(input) => {
              ref = input;
            }}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (highLightValue !== null) {
                  e.stopPropagation();
                  handleChangeTextInputEnter(highLightValue, e);
                } else {
                  handleChangeTextInputEnter(e.target.value, e);
                }
              }
              // if (e.code === "Tab") {
              //   if (highLightValue !== null) {
              //     handleChangeTextInputTab(highLightValue, e);
              //   } else {
              //     handleChangeTextInputTab(e.target.value, e);
              //   }
              // }
            }}
            {...params}
            fullWidth
          />
        );
      }}
    />
  );
};

export default TextInputEditor;
