import {
  Autocomplete,
  Popper,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    padding-right: 0;
  }
  .MuiOutlinedInput-input {
    padding: 8px 0 8px 4px;
  }
  .MuiIconButton-root {
    padding: 2px;
  }
`;

export const StyledFilterAutocomplete = styled(Autocomplete)`
  .MuiOutlinedInput-root {
    padding: 0;
    margin-top: 1vh;
  }
`;

export const StyledPopper = styled(Popper)`
  width: fit-content !important;
`;

export const StyledSelect = styled(Select)`
  height: 4.5vh;
  margin-top: 1vh;
`;

export const StyleMenuItem = styled(MenuItem)`
  padding: 0;
  padding-right: 16px;
`;
