import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../context/user/UserContext";
import { LOGIN } from "./utils";

const PrivateOutlet = () => {
  const [userContext] = useContext(UserContext);

  return userContext.token ? <Outlet /> : <Navigate to={LOGIN} />;
};

export default PrivateOutlet;
