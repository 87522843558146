import { Avatar, Chip, TextField, Tooltip } from "@mui/material";
import { createRef, useEffect, useState } from "react";
import { StyledAutocomplete } from "./styles";

const RoleChangeEditor = ({
  row,
  column,
  onRowChange,
  onClose,
  users,
  allUsers = false,
}) => {
  let ref = createRef();
  const [values, setValues] = useState([]);
  const [highLightValue, setHightLightValue] = useState(null);

  useEffect(() => {
    setValues(row[column.key] ? row[column.key] : []);
  }, [column.key, row]);

  const handleChangeRole = (values, role) => {
    setValues(values);
    onRowChange({ ...row, [column.key]: values });
  };

  const handleChangeValidateEnter = (value, e) => {
    const temp = [...values, value];
    setValues(temp);
    onRowChange({ ...row, [column.key]: temp }, true);
    e.stopPropagation();
  };

  useEffect(() => {
    ref.focus();
    ref.select();
  }, [ref]);

  return (
    <div>
      <StyledAutocomplete
        forcePopupIcon={false}
        classes={{ root: "styledRoot" }}
        key={column.key}
        multiple
        id={column.key}
        filterSelectedOptions
        autoFocus
        options={
          allUsers ? users : users.filter((user) => user.role === column.key)
        }
        value={values}
        isOptionEqualToValue={(option, val) => option._id === val._id}
        disableClearable
        onHighlightChange={(e, value) => {
          setHightLightValue(value);
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => {
            const fullName = `${option.firstName} ${option.lastName}`;
            return (
              <Chip
                {...getTagProps({ index })}
                avatar={
                  <Tooltip title={fullName}>
                    <Avatar
                      sx={{ bgcolor: option.color, width: 32, height: 32 }}
                    >
                      <span style={{ fontSize: "large" }}>
                        {option?.username?.toUpperCase()}
                      </span>
                    </Avatar>
                  </Tooltip>
                }
              />
            );
          });
        }}
        getOptionLabel={(option) => option.username}
        onChange={(e, values) => {
          handleChangeRole(values, column.key);
        }}
        renderInput={(params) => {
          return (
            <TextField
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  if (highLightValue !== null) {
                    handleChangeValidateEnter(highLightValue, e);
                  }
                }
              }}
              inputRef={(input) => {
                ref = input;
              }}
              {...params}
              fullWidth
            />
          );
        }}
      />
    </div>
  );
};

export default RoleChangeEditor;
