import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const StyledButton = styled(Button)`
  margin-top: 1rem;
`;

const ButtonComponent = ({
  title,
  disabled,
  onClickMethod,
  submit = false,
  variant = "outlined",
  style = {},
  startIcon,
  endIcon,
  color = "internalPrimary",
}) => (
  <StyledButton
    startIcon={startIcon}
    endIcon={endIcon}
    style={style}
    type={submit ? "submit" : "button"}
    variant={variant}
    disabled={disabled}
    onClick={onClickMethod}
    color={color}
  >
    {title}
  </StyledButton>
);

export default ButtonComponent;
