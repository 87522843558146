import React from "react";
import InputTextField from "../Widgets/InputTextField";
import Card from "../Widgets/Card";
import MyForm from "../Widgets/Form";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const AddEditUserComponent = ({
  isEditMode = false,
  formik,
  roles,
  children,
}) => {
  return (
    <Card>
      {!isEditMode && <h1>Ajouter un collaborateur</h1>}
      <MyForm onSubmit={formik.handleSubmit}>
        <InputTextField formik={formik} formikItemKey="lastName" label="Nom" />
        <InputTextField
          formik={formik}
          formikItemKey="firstName"
          label="Prénom"
        />
        <InputTextField
          formik={formik}
          formikItemKey="username"
          label="Pseudo"
        />
        <InputTextField
          formik={formik}
          formikItemKey="password"
          label="Mot de Passe"
        />
        <InputTextField formik={formik} formikItemKey="email" label="Email" />
        <FormControl
          fullWidth
          error={formik.touched["role"] && Boolean(formik.errors["role"])}
        >
          <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
          <Select
            labelId="select-role"
            id="select-role"
            label="Role"
            onChange={formik.handleChange("role")}
            value={formik.values.role}
          >
            {roles.map((role) => (
              <MenuItem value={role.id} key={role.id}>
                {role.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formik.touched["role"] && formik.errors["role"]}
          </FormHelperText>
        </FormControl>
        {children}
      </MyForm>
    </Card>
  );
};

export default AddEditUserComponent;
