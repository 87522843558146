import { connect } from "react-redux";

import DeleteUserModal from "../components/DeleteUserModal";
import { getClientList } from "../services/client";
import { deleteUser } from "../services/user";
import { clientActions } from "../store/client";
import { toastActions } from "../store/toast";
import { userActions } from "../store/user";

export default connect(
  () => ({}),
  (dispatch) => ({
    deleteUser: (user) => {
      dispatch(userActions.startGettingUsers());
      return deleteUser(user._id)
        .then(() => {
          dispatch(userActions.deleteUserToUserList(user._id));
          dispatch(clientActions.startGettingClients());
          getClientList()
            .then((data) => {
              dispatch(toastActions.updateToast("User supprimé avec succès", "success"));
              dispatch(clientActions.updateClientList(data));
            })
            .catch((err) => {
              dispatch(toastActions.updateToast(err.message, "error"));
            });
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
          throw new Error(err);
        });
    },
  }),
  (stateToPros, dispatchToProps, ownProps) => ({
    ...stateToPros,
    ...dispatchToProps,
    ...ownProps,
  })
)(DeleteUserModal);
