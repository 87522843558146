import { connect } from "react-redux";
import RoleHeaderWithFilter from "../../components/TabHeaders/RoleHeaderWithFilter";
import { CONFIG_CLIENTS } from "../../routes/utils";
import { tabActions, tabSelectors } from "../../store/tab";
import { userSelectors } from "../../store/user";

export default connect(
  (state) => ({
    tabSelected: tabSelectors.tabSelected(state),
    users: userSelectors.userList(state),
    filters: tabSelectors.filtersTabSelected(state),
  }),
  (dispatch) => ({
    updateFilter: (filterKey, value, tabSelected, columnName) => {
      dispatch(
        tabActions.updateFilter(filterKey, value, tabSelected, columnName)
      );
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => ({
    ...stateToProps,
    ...dispatchToProps,
    ...ownProps,
    updateFilter: (filterKey, value, location, columnName = null) => {
      dispatchToProps.updateFilter(
        filterKey,
        value,
        location.pathname === CONFIG_CLIENTS
          ? "configClient"
          : stateToProps.tabSelected,
        columnName
      );
    },
  })
)(RoleHeaderWithFilter);
