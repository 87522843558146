import { Provider } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";

import { BrowserRouter } from "react-router-dom";
import store from "./store";
import history from "./utils/history";
import { UserProvider } from "./context/user/UserContext";
import "./App.css";
import QueryProviderComponent from "./queries/provider";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { AxiosProvider } from "./context/axios/AxiosProvider";
import axios from "axios";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import frLocale from "date-fns/locale/fr";
import RoutesComponent from "./routes/Routes";
import GlobalContainer from "./containers/GlobalContainer";
import ToastContainer from "./containers/ToastContainer";
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
  },
});

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
      <QueryProviderComponent>
        <Provider store={store}>
          <BrowserRouter history={history}>
            <AxiosProvider axiosInstance={axiosInstance}>
              <UserProvider>
                <ThemeProvider theme={theme}>
                  <GlobalContainer>
                    <RoutesComponent />
                    <ToastContainer />
                  </GlobalContainer>
                </ThemeProvider>
              </UserProvider>
            </AxiosProvider>
          </BrowserRouter>
        </Provider>
      </QueryProviderComponent>
    </LocalizationProvider>
  );
}

export default App;
