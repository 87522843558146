import { format } from "date-fns";

const DateFormatter = ({ row, column, yearOnly = false }) => {
  return (
    <div>
      {row[column.key]
        ? yearOnly
          ? row[column.key]
          : format(new Date(row[column.key]), "dd-MM")
        : ""}
    </div>
  );
};

export default DateFormatter;
