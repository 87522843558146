/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGroupes } from "../../queries/system";

import { useMutation } from "react-query";
import { addClient } from "../../services/client";
import AddEditClientComponent from ".";
import MyModal from "../Widgets/Modal";
import DatePicker from "@mui/lab/DatePicker";
import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";

const EditClientComponent = ({
  tabsList,
  client,
  isModalOpen,
  setIsModalOpen,
  handleCancelClientEdition,
  handleValidateClientEdition,
}) => {
  const formik = useFormik({
    initialValues: client,
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      mutation.mutate(formik.values);
    },
  });

  const [selectedOnglets, setSelectedOnglets] = useState(client.onglets);

  const mutation = useMutation((params) => addClient(params), {
    onSuccess: () => {
      refetch();
      formik.resetForm({ values: client });
      setGroupeInputValue("");
      setSelectedOnglets([]);
    },
  });

  const [groupeInputValue, setGroupeInputValue] = useState("");

  const { data: groupesOptions = [], refetch } = useGroupes(groupeInputValue);

  const [expirationStatus, setExpirationStatus] = useState(false);

  useEffect(() => {
    if (client.expiration_year) {
      setExpirationStatus(true);
    }
  }, [client]);

  useEffect(() => {
    formik.setFieldValue("onglets", selectedOnglets);
  }, [selectedOnglets]);

  const handleChangeExpirationYear = (newYear) => {
    formik.setFieldValue("expiration_year", newYear.getFullYear());
  };

  const handleChangeExpirationStatus = (event) => {
    setExpirationStatus(event.target.checked);
    if (event.target.checked) {
      formik.setFieldValue("expiration_year", new Date().getFullYear());
    } else {
      formik.setFieldValue("expiration_year", null);
    }
  };

  return (
    <MyModal
      title={`Modification du client ${client?.name}`}
      open={isModalOpen}
      handleCancel={handleCancelClientEdition}
      handleValidate={() => {
        handleValidateClientEdition(formik.values);
      }}
    >
      <AddEditClientComponent
        formik={formik}
        tabsList={tabsList}
        groupesOptions={groupesOptions}
        isEditMode
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={expirationStatus} />}
            label="La collaboration avec ce client est terminée ?"
            onChange={handleChangeExpirationStatus}
            labelPlacement="start"
          />
        </FormGroup>
        {expirationStatus && (
          <DatePicker
            label="Année de fin de collaboration"
            views={["year"]}
            value={new Date(formik.values["expiration_year"], 1, 1)}
            onChange={handleChangeExpirationYear}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </div>
    </MyModal>
  );
};

export default EditClientComponent;
