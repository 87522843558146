import styled from "styled-components";
import LoginBackground from "../../assets/img/login_background.jpg";

export const StyledLogin = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${`url(${LoginBackground})`};
  background-color: white;
  background-position: center;
  background-size: cover;
  flex-grow: 1;
`;
