import { combineReducers } from "redux";
import types from "./types";

const userList = (state = [], action) => {
  switch (action.type) {
    case types.UPDATE_USER_LIST: {
      return action.payload.userList;
    }
    case types.ADD_USER_TO_LIST: {
      const tempList = [...state];
      tempList.push(action.payload.user);
      return tempList;
    }
    case types.DELETE_USER_TO_LIST: {
      return state.filter((i) => i._id !== action.payload.userID);
    }
    default:
      return state;
  }
};

const userListLoading = (state = false, action) => {
  switch (action.type) {
    case types.IS_LOADING_USERS: {
      return true;
    }
    case types.ADD_USER_TO_LIST:
    case types.DELETE_USER_TO_LIST:
    case types.UPDATE_USER_LIST: {
      return false;
    }
    default:
      return state;
  }
};

const collabSelected = (state = null, action) => {
  switch (action.type) {
    case types.UPDATE_COLLAB_SELECTED: {
      return action.payload.collabSelected;
    }
    default:
      return state;
  }
};

export default combineReducers({
  userList,
  userListLoading,
  collabSelected,
});
