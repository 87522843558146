const types = {
  UPDATE_TAB_DATA: "UPDATE_TAB_DATA",
  UPDATE_TAB_SELECTED: "UPDATE_TAB_SELECTED",
  UPDATE_TABS_CONFIG: "UPDATE_TABS_CONFIG",
  DELETE_TAB_CONFIG: "DELETE_TAB_CONFIG",
  IS_LOADING_GET_TABS: "IS_LOADING_GET_TABS",
  UPDATE_FILTERS: "UPDATE_FILTERS",
  RESET_FILTERS: "RESET_FILTERS",
  UPDATE_ROUTE_LOCATION: "UPDATE_ROUTE_LOCATION",
  REDIRECT_WITH_FILTERS: "REDIRECT_WITH_FILTERS",
};

export default types;
