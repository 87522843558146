/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useGroupes } from "../../queries/system";

import AlertComponent from "../Widgets/Alert";

import AddEditClientComponent from ".";
import MyModal from "../Widgets/Modal";

const AddClientComponent = ({
  tabsList,
  isAddingClient,
  setIsAddingClientStatus,
  addClient,
}) => {
  const initialValues = {
    siren: "",
    name: "",
    groupe_attached: "",
    date_debut_collaboration: new Date().getFullYear(),
    date_cloture_bilan: new Date(),
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      siren: Yup.string().matches(
        /^[0-9]{9}$/,
        "Un SIREN est composé de 9 chiffres"
      ),
      name: Yup.string().required("Nom de société requis"),
    }),
    onSubmit: async (values) => {
      addClient(formik.values)
        .then(() => {
          setClientRegistered(true);
          formik.resetForm({ values: initialValues });
          setGroupeInputValue("");
          setSelectedOnglets([]);
          setIsAddingClientStatus(false);
        })
        .catch(() => {
          setClientRegistered(false);
        });
    },
  });

  const [selectedOnglets, setSelectedOnglets] = useState([]);

  const [clientRegistered, setClientRegistered] = useState(null);

  const [groupeInputValue, setGroupeInputValue] = useState("");

  const { data: groupesOptions = [] } = useGroupes(groupeInputValue);

  useEffect(() => {
    formik.setFieldValue("groupe_attached", groupeInputValue);
  }, [groupeInputValue]);

  useEffect(() => {
    formik.setFieldValue("onglets", selectedOnglets);
  }, [selectedOnglets]);

  return (
    <MyModal
      open={isAddingClient}
      handleCancel={() => {
        setIsAddingClientStatus(false);
      }}
      handleValidate={() => {
        formik.handleSubmit();
      }}
    >
      <AddEditClientComponent
        formik={formik}
        tabsList={tabsList}
        groupesOptions={groupesOptions}
      >
        {clientRegistered && (
          <AlertComponent msg="Dossier client ajouté avec succès" />
        )}
      </AddEditClientComponent>
    </MyModal>
  );
};

export default AddClientComponent;
