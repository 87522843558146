/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CircularProgress, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AddUserContainer from "../../containers/AddUserContainer";
import { useRoles } from "../../queries/user";
import EditUser from "../AddEditUserComponent/EditUser";
import ButtonComponent from "../Widgets/Button";
import DeleteIcon from "@mui/icons-material/Delete";


import DeleteUserContainer from "../../containers/DeleteUserContainer";
import { UserContext } from "../../context/user/UserContext";

const GestionUtilisateursComponent = ({
  isLoadingUsers,
  userList,
  updateUserToUserList,
}) => {
  const [isAddingUser, setIsAddingUserStatus] = useState(false);
  const [usersWithCorrectRole, setUsersWithCorrectRole] = useState(null);
  const [rolesMap, setRoleMap] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalDeleteUser, setIsModalDeleteUser] = useState(false);
  const [userSelected, setUserSelected] = useState(null);

  const { data: roles = [], isLoading } = useRoles();
  const [userContext] = useContext(UserContext);

  useEffect(() => {
    if (roles.length > 0) {
      let tempRoleMap = {};
      roles.forEach((role) => {
        tempRoleMap = {
          ...tempRoleMap,
          [role.id]: role.name,
        };
      });
      setRoleMap(tempRoleMap);
    }
  }, [roles]);

  useEffect(() => {
    if (rolesMap && userList.length > 0) {
      const userWithRole = [];
      userList.forEach((user) => {
        if (user.role) {
          userWithRole.push({
            ...user,
            role: rolesMap[user.role],
            roleID: user.role,
          });
        }
      });
      setUsersWithCorrectRole(userWithRole);
    }
  }, [rolesMap, userList]);

  const handleClickOnUser = (user) => {
    setUserSelected(user);
    setModalOpen(true);
  };

  const handleDeleteUser = (user) => {
    setUserSelected(user);
    setIsModalDeleteUser(true);
  };

  return (
    <Box display='flex' flexDirection='column' width='100vw' margin='0 5rem'>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <h1>Liste des Utilisateurs</h1>
        <ButtonComponent
          title={"Ajouter un utilisateur"}
          variant="contained"
          disabled={isAddingUser}
          onClickMethod={() => {
            setIsAddingUserStatus(true);
          }}
        />
      </div>
      {isLoading || isLoadingUsers ? (
        <CircularProgress />
      ) : (
        <>
          <Box padding='2rem 15rem'>
            {usersWithCorrectRole?.map((user) => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignIitems='center'
                border='1px solid aliceblue'
                padding='1rem 2rem'
                fontSize='large'
                fontWeight='500'
                sx={{ ":hover": { backgroundColor: "#1976d2", color: "white", cursor: "pointer" } }}
                key={user._id}
                onClick={() => {
                  handleClickOnUser(user);
                }}
              >
                <span>{user.username}</span>
                <Box
                  width='20%'
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <span>{user.role}</span>
                  {userContext.profile._id !== user._id && (
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteUser(user);
                      }}
                    >
                      <DeleteIcon color="error" variant="contained" />
                    </IconButton>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
      {isModalOpen && (
        <EditUser
          updateUserToUserList={updateUserToUserList}
          user={userSelected}
          roles={roles}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        />
      )}
      {isAddingUser && (
        <AddUserContainer
          isAddingUser={isAddingUser}
          setIsAddingUserStatus={setIsAddingUserStatus}
        />
      )}
      {isModalDeleteUser && (
        <DeleteUserContainer
          user={userSelected}
          isModalDeleteUser={isModalDeleteUser}
          setIsModalDeleteUser={setIsModalDeleteUser}
        />
      )}
    </Box>
  );
};

export default GestionUtilisateursComponent;
