import { connect } from "react-redux";

import { toastActions, toastSelectors } from "../store/toast";

import Toast from "../components/Widgets/Toast";

export default connect(
  (state) => ({
    msg: toastSelectors.msg(state),
    severity: toastSelectors.severity(state),
  }),
  (dispatch) => ({
    resetToast: () => {
      dispatch(toastActions.resetToast());
    },
  })
)(Toast);
