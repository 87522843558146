/* eslint-disable import/no-anonymous-default-export */

const userList = (state) => state.user.userList;
const isLoadingUsers = (state) => state.user.userListLoading;
const collabSelected = (state) => state.user.collabSelected;

export default {
  userList,
  isLoadingUsers,
  collabSelected
};
