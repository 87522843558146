import { Typography } from "@mui/material";
import MyModal from "../Widgets/Modal";
import { useDispatch } from "react-redux";
import { tabActions } from "../../store/tab";
import { deleteTab } from "../../services/tab";
import { getOnglets } from "../../services/system";
import { toastActions } from "../../store/toast";
import { useMutation } from "react-query";

export const DeleteOngletModal = ({
  isOpen,
  onClose,
  onglet,
}) => {

  const dispatch = useDispatch();
  const mutation = useMutation(() => deleteTab({ id: onglet.id }), {
    onSuccess: (data) => {
      dispatch(tabActions.deleteTabConfig(onglet.id));
      getOnglets()
        .then((onglets) => {
          dispatch(tabActions.updateTabsConfig(onglets));
          dispatch(toastActions.updateToast("Onglet supprimé avec succès", "success"));
        })
        .catch((err) => {
          dispatch(toastActions.updateToast(err.message, "error"));
        });
      onClose();
    },
    onError: () => { },
  });

  return (
    <MyModal
      title={`Suppression de l'onglet ${onglet.name}`}
      open={isOpen}
      handleCancel={onClose}
      handleValidate={() => {
        mutation.mutate();
      }}
    >
      <Typography>
        Êtes-vous sûr de vouloir supprimer cet onglet ?
      </Typography>
      <Typography>
        Cette action supprimera l'onglet du système ainsi que les données clients associées.
      </Typography>
    </MyModal>
  );
}

