/* eslint-disable import/no-anonymous-default-export */
import types from "./types";

const updateTab = (ongletID, tabData) => ({
  type: types.UPDATE_TAB_DATA,
  payload: {
    ongletID,
    tabData,
  },
});

const updateTabsConfig = (tabsConfig) => ({
  type: types.UPDATE_TABS_CONFIG,
  payload: {
    tabsConfig,
  },
});

const deleteTabConfig = (ongletID) => ({
  type: types.DELETE_TAB_CONFIG,
  payload: {
    ongletID,
  },
});

const startGettingTabs = () => ({
  type: types.IS_LOADING_GET_TABS,
  payload: {},
});

const updateTabSelected = (ongletID) => ({
  type: types.UPDATE_TAB_SELECTED,
  payload: {
    ongletID,
  },
});

const updateFilter = (filterKey, value, tabSelected, columnName) => ({
  type: types.UPDATE_FILTERS,
  payload: {
    tabSelected,
    filterKey,
    value,
    columnName,
  },
});

const redirectWithFilter = (tab, filters) => ({
  type: types.REDIRECT_WITH_FILTERS,
  payload: {
    tab,
    filters,
  },
});

const resetFilterByTab = (tabSelected) => ({
  type: types.RESET_FILTERS,
  payload: {
    tabSelected,
  },
});

const updateRouteToStore = (location) => ({
  type: types.UPDATE_ROUTE_LOCATION,
  payload: {
    location,
  },
});

export default {
  updateTab,
  updateTabSelected,
  startGettingTabs,
  updateTabsConfig,
  deleteTabConfig,
  updateFilter,
  resetFilterByTab,
  updateRouteToStore,
  redirectWithFilter,
};
