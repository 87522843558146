import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { useState, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useLocation } from "react-router-dom";
import HeaderTitle from "./HeaderTitle";

const MultiAutoCompleteCheckboxHeaderFilter = ({
  columnKey,
  columnName,
  columnSubName,
  filters,
  updateFilter,
  columnType,
  options,
}) => {
  const [filterValue, setFilterValue] = useState([]);
  const location = useLocation();
  const handleChangeFilter = (values) => {
    updateFilter(
      columnKey,
      { type: columnType, value: values },
      location,
      columnName
    );
    setFilterValue(values);
  };

  useEffect(() => {
    if (filters?.[columnKey]?.value) {
      setFilterValue(filters[columnKey].value);
    } else {
      setFilterValue([]);
    }
  }, [columnKey, filters]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <HeaderTitle columnName={columnName} columnSubName={columnSubName} />

      <Autocomplete
        multiple
        onChange={(event, newValues) => {
          handleChangeFilter(newValues);
        }}
        value={filterValue}
        size="small"
        style={{ marginTop: "1vh" }}
        limitTags={2}
        key={columnKey}
        id={columnKey}
        disableClearable
        options={options}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ padding: 0 }}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option}
          </li>
        )}
        renderInput={(params) => <TextField {...params} />}
      />
    </div>
  );
};

export default MultiAutoCompleteCheckboxHeaderFilter;
