/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from "react";

import MyModal from "../Widgets/Modal";
import { Box, Typography } from "@mui/material";
import MultiAction from "../TabActions/MultiAction";
import TextInputAction from "../TabActions/TextInputAction";
import DateAction from "../TabActions/DateAction";
import PersonAction from "../TabActions/PersonAction";

const UpdateSelectedRowsModal = ({
    rowsCount,
    isModalOpen,
    onClose,
    onSubmit,
    columns,
    tabConfig
}) => {

    const [values, setValues] = useState({});
    const nonAdminColumns = useMemo(() => {
        return columns.filter((column) => !column.admin && column.key !== 'select-row');
    }, [columns]);

    const handleClose = () => {
        setValues({});
        onClose();
    }

    const handleChange = (column, value) => {
        setValues({ ...values, [column.key]: value });
    }

    const handleSubmit = () => {
        onSubmit(values);
        handleClose();
    }

    const getComponent = useCallback((column) => {
        const columnConfig = Object.values(tabConfig.columns).find((c) => c.id === column.key);
        switch (column.type) {
            case "multi":
                return (
                    <MultiAction
                        options={columnConfig?.options || []}
                        onChange={(value) => handleChange(column, value)}
                    />
                );
            case "text":
                return <TextInputAction id={column.id} onChange={(value) => handleChange(column, value)} />;
            case "boolean":
                return (
                    <MultiAction
                        options={["Oui", "Non", ""]}
                        onChange={(value) => handleChange(column, value)}
                    />
                );
            case "ok":
                return (
                    <MultiAction
                        options={["OK", "N/A", ""]}
                        onChange={(value) => handleChange(column, value)}
                    />
                );
            case "date":
                return <DateAction onChange={(value) => handleChange(column, value)} />;
            case "person":
                return <PersonAction onChange={(value) => handleChange(column, value)} />;
            default:
                return <TextInputAction id={column.id} onChange={(value) => handleChange(column, value)} />;

        }
    }, [values]);

    return (
        <MyModal
            title={`Modifier ${rowsCount} lignes`}
            open={isModalOpen}
            handleCancel={handleClose}
            handleValidate={() => {
                handleSubmit();
            }}
        >
            <Typography as='p'>
                Attention, vous allez modifier {rowsCount} lignes. Les actions suivantes seront appliquées à toutes les lignes sélectionnées.
            </Typography>
            <Box
                marginTop='20px'
                display='flex'
                flexDirection='column'
                alignItems='center'
                width='50%'
                justifyContent='center'
                margin='0 auto'
            >
                {nonAdminColumns.map((column) => {
                    const component = getComponent(column);
                    return (
                        <Box my='20px' width='100%' key={column.key}>
                            <Typography as='p' textAlign='center'>
                                {column.name}
                            </Typography>
                            <Box>
                                <>{component}</>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </MyModal>
    );
};

export default UpdateSelectedRowsModal;
