import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { updateLinkedStatus } from "../../services/tab";
import { CLIENTS_HEADER } from "../../store/tab/utils";
import ButtonComponent from "../Widgets/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const EditLinked = ({ tabConfig, tabSelected, getOngletsConfig }) => {
  const [savedLinkedStatus, setSavedLinkedStatus] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [linkedStatus, setLinKedStatus] = useState(false);

  const [clientsColumnsVisibled, setClientsColumnsVisibled] = useState({});

  useEffect(() => {
    let temp = tabConfig?.clientsColumnsVisibled;
    CLIENTS_HEADER().forEach((col) => {
      temp = {
        ...temp,
        [col.key]: tabConfig?.clientsColumnsVisibled?.[col.key],
      };
    });
    setClientsColumnsVisibled(temp);
  }, [tabConfig]);

  const handleChangeCheckCol = (e, colKey) => {
    setIsModified(true);
    const temp = {
      ...clientsColumnsVisibled,
      [colKey]: e.target.checked,
    };
    setClientsColumnsVisibled(temp);
  };

  useEffect(() => {
    if (tabConfig) {
      setSavedLinkedStatus(tabConfig.linked);
      setLinKedStatus(tabConfig.linked);
    }
  }, [tabConfig]);

  const mutationVisibility = useMutation(
    () => updateLinkedStatus(linkedStatus, tabSelected, clientsColumnsVisibled),
    {
      onSuccess: () => {
        getOngletsConfig();
      },
      onError: () => {},
    }
  );

  const switchLinkedStatus = (e) => {
    setIsModified(true);
    setLinKedStatus(e.target.checked);
  };

  const validateLinkedStatus = () => {
    mutationVisibility.mutate();
  };
  return (
    <>
      {savedLinkedStatus !== null && (
        <>
          <div>
            Onglet lié à la table des clients ?
            <Switch
              checked={linkedStatus}
              onChange={switchLinkedStatus}
              color="secondary"
            />
          </div>
          <div>
            {linkedStatus &&
              CLIENTS_HEADER().map((clientCol, i) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => handleChangeCheckCol(e, clientCol.key)}
                        {...clientCol.name}
                        checked={clientsColumnsVisibled[clientCol.key]}
                      />
                    }
                    label={clientCol.name}
                  />
                );
              })}
          </div>
          {isModified && (
            <ButtonComponent
              color="secondary"
              variant="contained"
              title="Valider"
              onClickMethod={validateLinkedStatus}
            />
          )}
        </>
      )}
    </>
  );
};

export default EditLinked;
