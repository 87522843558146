import { TextField } from "@mui/material";
import React from "react";

const TextInputAction = ({
    id,
    onChange
}) => {
    const handleChangeTextInput = (value, e) => {
        onChange(value);
    };
    return (
        <TextField
            id={id}
            onChange={(e) => {
                handleChangeTextInput(e.target.value, e);
            }}
            fullWidth
        />
    );
};

export default TextInputAction;
