import { connect } from "react-redux";

import { tabSelectors } from "../store/tab";
import { userActions, userSelectors } from "../store/user";
import PersonPicker from "../components/PersonPicker";

export default connect(
  (state) => ({
    userList: userSelectors.userList(state).slice(0, -1),
    tabsList: tabSelectors.tabsList(state),
    collabSelected: userSelectors.collabSelected(state),
  }),
  (dispatch) => ({
    updateCollabSelected: (collabSelected) => {
      dispatch(userActions.updateCollabSelected(collabSelected));
    },
  })
)(PersonPicker);
