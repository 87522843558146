import { connect } from "react-redux";

import { tabActions, tabSelectors } from "../store/tab";

import DashboardTable from "../components/Dashboard/DashboardTable";

const getEmptyValueByColumnTypeMAP = {
  boolean: false,
  date: [""],
  person: " ",
  text: " ",
  multi: [""],
  ok: [""],
};

export default connect(
  (state) => ({
    tabsColumnsMapNamed: tabSelectors.tabsColumnsMapNamed(state),
    tabsConfig: tabSelectors.tabsConfig(state),
  }),
  (dispatch) => ({
    redirectWithFilter: (tab, filters) => {
      dispatch(tabActions.redirectWithFilter(tab, filters));
    },
  }),
  (stateToProps, dispatchToProps, ownProps) => {
    return {
      ...stateToProps,
      ...dispatchToProps,
      ...ownProps,
      redirectWithFilter: (tab, columnType, columnName, columnId) => {
        const collabSelected = ownProps.collabSelected;
        const filters = [
          {
            column: columnId,
            columnType,
            filterValue: getEmptyValueByColumnTypeMAP[columnType],
            columnName,
          },
          {
            column: collabSelected.role,
            columnType: "person",
            filterValue: collabSelected.username,
            columnName: "Collab",
          },
        ];
        dispatchToProps.redirectWithFilter(tab, filters);
      },
    };
  }
)(DashboardTable);
