import { combineReducers } from "redux";
import types from "./types";

const clientList = (state = [], action) => {
  switch (action.type) {
    case types.UPDATE_CLIENT_LIST: {
      return action.payload.clientList;
    }
    case types.ADD_CLIENT_TO_LIST: {
      const tempList = [...state];
      tempList.push(action.payload.client);
      return tempList;
    }
    default:
      return state;
  }
};

const clientListLoading = (state = false, action) => {
  switch (action.type) {
    case types.IS_LOADING_CLIENTS: {
      return true;
    }
    case types.UPDATE_CLIENT_LIST: {
      return false;
    }
    default:
      return state;
  }
};

const yearSelected = (state = new Date().getFullYear(), action) => {
  switch (action.type) {
    case types.UPDATE_YEAR: {
      return action.payload.year;
    }
    default:
      return state;
  }
};

export default combineReducers({
  clientList,
  clientListLoading,
  yearSelected,
});
