const HeaderTitle = ({ columnSubName, columnName }) => (
  <div style={{ display: "flex", flexDirection: "column", lineHeight: "4vh" }}>
    {columnSubName ? (
      <>
        <span style={{ lineHeight: "2vh" }}>{columnName}</span>
        <span style={{ lineHeight: "2vh" }}>{columnSubName}</span>
      </>
    ) : (
      <span style={{ lineHeight: "4vh" }}>{columnName}</span>
    )}
  </div>
);

export default HeaderTitle;
