import styled from "styled-components";

export const StyledOngletwrapper = styled("div")`
  max-width: 100%;
  width: 90vw;
  margin: 0px 5vw;
  height: calc(100vh - 56px);
  ${({ theme }) =>
    `${theme.breakpoints.up("xs")} and (orientation: landscape)`} {
    height: calc(100vh - 48px);
  }
  ${({ theme }) => `${theme.breakpoints.up("sm")}`} {
    height: calc(100vh - 64px);
  }
`;
