import { useQuery } from "react-query";
import { getOnglets, getGroupes } from "../../services/system";

export const useOnglets = (options) => {
  return useQuery(["onglets"], getOnglets, { ...options });
};

export const useGroupes = () => {
  return useQuery(["groupes"], getGroupes, {
    staleTime: 10 * 1000,
  });
};
