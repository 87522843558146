import { createRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { createPortal } from "react-dom";

const DateEditor = ({ row, column, onRowChange }) => {
  const [value, setValue] = useState(null);
  let ref = createRef();

  useEffect(() => {
    if (row[column.key]) {
      setValue(new Date(row[column.key]));
    }
  }, [column.key, row]);

  const handleChangeDate = (date) => {
    setValue(date);
    onRowChange({ ...row, [column.key]: date });
  };

  useEffect(() => {
    ref.setFocus();
  }, [ref]);

  return (
    <DatePicker
      popperContainer={({ children }) => createPortal(children, document.body)}
      ref={(input) => (ref = input)}
      selected={value}
      onChange={handleChangeDate}
      dateFormat="dd-MM"
    />
  );
};

export default DateEditor;
