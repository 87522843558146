import React, { useState } from "react";

const AxiosContext = React.createContext([{}, () => {}]);

const AxiosProvider = ({ children, axiosInstance }) => {
  const [myInterceptor, setMyInterceptor] = useState(null);

  const setInterceptor = (token) => {
    setMyInterceptor(
      axiosInstance.interceptors.request.use(
        (config) => {
          config.headers = {
            Authorization: `Bearer ${token}`,
          };
          return config;
        },
        (error) => {
          Promise.reject(error);
        }
      )
    );
  };

  const removeInterceptor = () => {
    axiosInstance.interceptors.request.eject(myInterceptor);
    delete axiosInstance.defaults.headers.common["Authorization"];
  };

  return (
    <AxiosContext.Provider
      value={{
        setInterceptor: setInterceptor,
        removeInterceptor: removeInterceptor,
      }}
    >
      {children}
    </AxiosContext.Provider>
  );
};

export { AxiosContext, AxiosProvider };
