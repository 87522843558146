import styled from "styled-components";

export const StyledGlobalDivDashboard = styled("div")`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const StyledContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const StyledGrid = styled("div")`
  display: grid;
  grid-template-columns: 40% 30% 30%;
  grid-auto-rows: 35px;
  justify-content: center;
  align-items: center;
  overflow-y: scroll;
  width: 70%;
  border: ${({ theme }) => `1px solid ${theme.palette.internalPrimary.main}`};
  border-radius: 5px;
  > * {
    height: 100%;
    overflow: hidden;
  }
`;

export const StyledTableContainer = styled("div")`
  height: 85%;
  width: 90%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: 35vh;
  row-gap: 3vh;
`;

export const StyledOngletName = styled("span")`
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.palette.internalThird.main};
  color: ${({ theme }) => theme.palette.internalPrimary.main};
  font-weight: 700;
`;

export const StyledHeader = styled("span")`
  background-color: ${({ theme }) => theme.palette.internalThird.main};
  color: ${({ theme }) => theme.palette.internalPrimary.main};
  font-weight: 700;
`;

export const StyledCellHeader = styled("div")`
  display: flex;
  > * {
    width: 100%;
    line-height: 35px;
    vertical-align: middle;
    word-break: break-all;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const StyledSubGrid = styled("div")`
  grid-column-start: 1;
  grid-column-end: 4;
  display: grid;
  grid-template-columns: 40% 30% 30%;
  :hover {
    border: ${() => `0.2px solid black`};
    opacity: 0.6;
    cursor: pointer;
  }
  :hover * {
    background-color: #1976d2 !important;
    color: white;
  }
`;
export const StyledCell = styled("div")`
  display: flex;
  > * {
    width: 100%;
    line-height: 35px;
    vertical-align: middle;
    word-break: break-all;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

export const StyledDataVide = styled("span")`
  background-color: ${({ data }) => `rgb(255, 0, 0, ${data})`};
  text-align: center;
`;

export const StyledDataNonVide = styled("span")`
  background-color: ${({ data }) => `rgb(124, 252, 0, ${data})`};
  text-align: center;
`;

export const StyledColumnName = styled("span")`
  background-color: ${({ theme }) => theme.palette.internalThird.main};
  color: ${({ theme }) => theme.palette.internalPrimary.main};
  font-weight: 600;
`;
